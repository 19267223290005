import * as _ from 'lodash';
import Vue from 'vue';

const vApp = new Vue({
    
});
global.vApp = vApp;

// Address Events

vApp.$on('address:getRegions', function () {
    const state = { active: false };
    return (req) => {
        const countryCode = req && req.countryCode;
        if (state.active) { return; }
        // state.active = true;
        console.log('address:getRegions');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/region/list/${countryCode}`,
            type: 'GET',
            contentType: "application/json",
            dataType: 'json',
        }).then((res) => {
            state.active = false;
            state.cart = res;
            console.log(`address:getRegions:then`, res);
            vApp.$emit(`address:getRegions:then`, res);
            vApp.$emit('loadingBar:hide');
        });
    }
}());

// Buy Widget Events

vApp.$on('buywidget:post', function () {
    const state = { active: false };
    return ({
        buildCfg,
        filters
    }) => {
        console.log('buildCfg', buildCfg);
        if (!buildCfg) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('buywidget:post');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/buywidget/${buildCfg.Adv ? 'adv' : 'simple'}`,
            type: 'POST',
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify({
                ...buildCfg,
                Filters: filters || []
            })
        }).then((res) => {
            state.active = false;
            state.cart = res;
            console.log('buywidget:post:then', res);
            vApp.$emit('buywidget:post:then', res);
            vApp.$emit('loadingBar:hide');
        }).catch((err) => {
            state.active = false;
            console.error('buywidget:post:catch', err);
            vApp.$emit('buywidget:post:catch', err);
            vApp.$emit('loadingBar:hide');
        });
    }
}());

// Cart Events

vApp.$on('cart:getCart', function () {
    const state = { active: false };
    return (req) => {
        const cartName = req && req.cartName;
        if (state.active) { return; }
        // state.active = true;
        console.log('cart:getCart');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/cart/${cartName ? cartName : 'Default'}`,
            type: 'GET',
            contentType: "application/json",
            dataType: 'json',
        }).then((res) => {
            state.active = false;
            state.cart = res;
            console.log(`cart:getCart${cartName ? (':' + cartName) : ''}:then`, res);
            vApp.$emit(`cart:getCart${cartName ? (':' + cartName) : ''}:then`, res);
            vApp.$emit('loadingBar:hide');
        });
    }
}());

vApp.$on('cart:getCartItemCount', function () {
    const state = { active: false };
    return (req) => {
        const cartName = req && req.cartName;
        if (state.active) { return; }
        // state.active = true;
        console.log('cart:getCartItemCount');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/cart/cartItemCount/${cartName ? cartName : 'Default'}`,
            type: 'GET',
            contentType: "application/json",
            dataType: 'json',
        }).then((res) => {
            state.active = false;
            console.log(`cart:getCartItemCount${cartName ? (':' + cartName) : ''}:then`, res);
            vApp.$emit(`cart:getCartItemCount${cartName ? (':' + cartName) : ''}:then`, res);
            vApp.$emit('loadingBar:hide');
        });
    }
}());

vApp.$on('cart:postCart', function () {
    const state = { active: false };
    return ({ skuCode, skuCodes, modelCode, qty, cartName = 'Default', specifications }) => {
        if (!skuCode && !skuCodes) { return; }
        if (_.isNil(qty)) { return; }
        if (_.isNil(cartName)) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('cart:postCart');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: skuCodes ? `/api/cart/link/${cartName === 'Default' ? qty: 1}/${cartName}?${$.param({ skuCode: [...skuCodes] })}` : `/api/cart/${skuCode}/${qty}/${cartName}`,
            type: 'POST',
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify({
                specifications: specifications && [...specifications],
                modelCode
            })
        }).then((res) => {
            state.active = false;
            console.log('cart:postCart:then', res);
            vApp.$emit('cart:postCart:then', res);
            vApp.$emit('loadingBar:hide');
            _.delay(() => vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, `addToCartThen.${cartName === 'Default' ? 'cart' : (cartName === 'Service' ? 'service' : 'wishlist') }`)
            }));
        }).catch((err) => {
            state.active = false;
            console.error('cart:postCart:catch', err);
            vApp.$emit('cart:postCart:catch', err);
            vApp.$emit('loadingBar:hide');
            _.delay(() => vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, `addToCartCatch.${cartName === 'Default' ? 'cart' : (cartName === 'Service' ? 'service' : 'wishlist')}`),
                err: true
            }));
        });
    }
}());

vApp.$on('cart:putCart', function () {
    const state = { active: false };
    return ({ skuCode, skuCodes, modelCode, linkId, qty, cartName = 'Default' }) => {
        if (!skuCode && (!skuCodes && !linkId)) { return; }
        if (_.isNil(qty)) { return; }
        if (_.isNil(cartName)) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('cart:putCart');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: linkId ? `/api/cart/link/${qty}/${cartName}?${$.param({ skuCode: [...skuCodes], modelCode, linkId })}` : `/api/cart/${skuCode}/${qty}/${cartName}`,
            type: 'PUT',
            contentType: "application/json",
            dataType: 'json'
        }).then((res) => {
            state.active = false;
            console.log('cart:putCart:then', res);
            vApp.$emit('cart:putCart:then', res);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, `updateCartThen.${cartName === 'Default' ? 'cart' : 'wishlist'}`)
            });
        }).catch((err) => {
            state.active = false;
            console.error('cart:putCart:catch', err);
            vApp.$emit('cart:putCart:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, `updateCartCatch.${cartName === 'Default' ? 'cart' : 'wishlist'}`),
                err: true
            });
        });
    }
}());


vApp.$on('cart:putServiceCart', function () {
    const state = { active: false };
    return ({ skuCode, problemDescription, serialNumber, genericPart, serialFGCode, serialModelCode, serialModelName }) => {
        if (_.isNil(skuCode)) { return; }
        if (_.isNil(serialNumber)) { return; }
        if (_.isNil(genericPart)) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('cart:putServiceCart');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/cart/serviceCart`,
            type: 'PUT',
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify({
                serviceFGCode: skuCode,
                problemDescription,
                serialNumber,
                genericPart,
                serialFGCode,
                serialModelCode,
                serialModelName
            })
        }).then((res) => {
            state.active = false;
            console.log('cart:putServiceCart:then', res);
            vApp.$emit('cart:putServiceCart:then', res);
            vApp.$emit('loadingBar:hide');
            _.delay(() => vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, `putServiceCartThen`)
            }));
        }).catch((err) => {
            state.active = false;
            console.error('cart:putServiceCart:catch', err);
            vApp.$emit('cart:putServiceCart:catch', err);
            vApp.$emit('loadingBar:hide');
            _.delay(() => vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, `putServiceCartCatch`),
                err: true
            }));
        });
    }
}());

vApp.$on('cart:putServiceCartImages', function () {
    const state = { active: false };
    return ({ data }) => {
        if (_.isNil(data)) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('cart:putServiceCartImages');
        console.log(data);
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/cart/serviceCart/images`,
            type: 'PUT',
            dataType: 'html',
            processData: false,
            contentType: false,
            data
        }).then((res) => {
            state.active = false;
            console.log('cart:putServiceCartImages:then', res);
            vApp.$emit('cart:putServiceCartImages:then', res);
            vApp.$emit('loadingBar:hide');
            _.delay(() => vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, `putServiceCartImagesThen`)
            }));
        }).catch((err) => {
            state.active = false;
            console.error('cart:putServiceCartImages:catch', err);
            vApp.$emit('cart:putServiceCartImages:catch', err);
            vApp.$emit('loadingBar:hide');
            _.delay(() => vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, `putServiceCartImagesCatch`),
                err: true
            }));
        });
    }
}());


vApp.$on('cart:transferCart', function () {
    const state = { active: false };
    return () => {
        if (state.active) { return; }
        state.active = true;
        console.log('cart:transferCart');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/cart/transfercart`,
            type: 'PATCH',
            contentType: "application/json",
            dataType: 'json',
        }).then((res) => {
            state.active = false;
            console.log('cart:transferCart:then', res);
            vApp.$emit('cart:transferCart:then', res);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'addToCartThen.cart')
            });
        }).catch((err) => {
            state.active = false;
            console.error('cart:transferCart:catch', err);
            vApp.$emit('cart:transferCart:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'addToCartCatch.cart'),
                err: true
            });
        });
    }
}());

vApp.$on('cart:transferLine', function () {
    const state = { active: false };
    return ({ skuCode, cartName = 'Default' }) => {
        if (!skuCode) { return; }
        if (_.isNil(cartName)) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('cart:transferLine');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/cart/transferline/${skuCode}`,
            type: 'PATCH',
            contentType: "application/json",
            dataType: 'json',
        }).then((res) => {
            state.active = false;
            console.log('cart:transferLine:then', res);
            vApp.$emit('cart:transferLine:then', res);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, `addToCartThen.${cartName === 'Default' ? 'cart' : 'wishlist'}`)
            });
        }).catch((err) => {
            state.active = false;
            console.error('cart:transferLine:catch', err);
            vApp.$emit('cart:transferLine:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, `addToCartCatch.${cartName === 'Default' ? 'cart' : 'wishlist'}`),
                err: true
            });
        });
    }
}());

// Checkout events

vApp.$on('checkout:getAddress', function () {
    const state = { active: false };
    return ({ isShipping }) => {
        if (state.active) { return; }
        state.active = true;
        console.log('checkout:getAddress');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/checkout/getAddress/${isShipping}`,
            type: 'GET',
            contentType: "application/json",
            dataType: 'json'
        }).then((res) => {
            state.active = false;
            console.log('checkout:getAddress:then', res);
            vApp.$emit('checkout:getAddress:then', res);
            vApp.$emit('loadingBar:hide');
        }).catch((err) => {
            state.active = false;
            console.error('checkout:getAddress:catch', err);
            vApp.$emit('checkout:getAddress:catch', err);
            vApp.$emit('loadingBar:hide');
        });
    }
}());

vApp.$on('checkout:postAddress', function () {
    const state = { active: false };
    return ({ data, cartName = 'Default'}) => {
        if (!data) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('checkout:postAddress');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/checkout/saveAddress/${cartName}`,
            type: 'POST',
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify({
                ...data
            })
        }).then((res) => {
            console.log('res', res);
            state.active = false;
            console.log('checkout:postAddress:then', res);
            vApp.$emit('checkout:postAddress:then', res);
            vApp.$emit('loadingBar:hide');
            if (data.Subscribe) {
                return vApp.$emit('snackbar:show', {
                    msg: _.get(snackbarCfg, 'postAddressSubscribeThen')
                });
            }
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postAddressThen')
            });
        }).catch((err) => {
            console.log('err', err);
            state.active = false;
            console.error('checkout:postAddress:catch', err);
            vApp.$emit('checkout:postAddress:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postAddressCatch'),
                err: true
            });
        });
    }
}());

// CardConnect Only - remove after WorldPay migration
vApp.$on('cart:postPayment', function () {
    const state = { active: false };
    return ({ tokenId, expiry, amount, address, cartName = 'Default' }) => {
        if (!tokenId) {
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postPaymentTokenCatch'),
                err: true
            });
            return;
        }
        if (!expiry) { return; }
        if (!amount) { return; }
        if (!address) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('cart:postPayment');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/cart/attachCardPayment/${cartName}`,
            type: 'POST',
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify({
                TokenId: tokenId,
                Expiry: expiry,
                Amount: amount,
                BillingAddress: address
            })
        }).then((res) => {
            state.active = false;
            console.log('cart:postPayment:then', res);
            vApp.$emit('cart:postPayment:then', res);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postPaymentThen')
            });
        }).catch((err) => {
            state.active = false;
            console.error('cart:postPayment:catch', err);
            vApp.$emit('cart:postPayment:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postPaymentCatch'),
                err: true
            });
        });
    }
}());

// CardConnect only - remove after WorldPay migration
vApp.$on('cart:postPaypal', function () {
    const state = { active: false };
    return ({ orderId, authCode, cardHolder, amount, eligibility, eligibilityType, authorization, cartName = 'Default' }) => {
        if (!orderId) {
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postPaypalTokenCatch'),
                err: true
            });
            return;
        }
        if (!authCode) { return; }
        if (!amount) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('cart:postPaypal');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/cart/attachPayPalPayment/${cartName}`,
            type: 'POST',
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify({
                OrderId: orderId,
                AuthorizationCode: authCode,
                Amount: +amount,
                CardHolder: cardHolder,
                Eligibility: eligibility,
                EligibilityType: eligibilityType,
                AuthorizationResponse: JSON.stringify(authorization || {})
            })
        }).then((res) => {
            state.active = false;
            console.log('cart:postPaypal:then', res);
            vApp.$emit('cart:postPaypal:then', res);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postPaymentThen')
            });
        }).catch((err) => {
            state.active = false;
            console.error('cart:postPaypal:catch', err);
            vApp.$emit('cart:postPaypal:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postPaymentCatch'),
                err: true
            });
        });
    }
}());

vApp.$on('checkout:postShipping', function () {
    const state = { active: false };
    return ({ shippingId, cartName = 'Default' }) => {
        if (!shippingId) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('checkout:postShipping');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/checkout/saveShipping/${cartName}`,
            type: 'POST',
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify({
                ShippingId: shippingId
            })
        }).then((res) => {
            state.active = false;
            console.log('checkout:postShipping:then', res);
            vApp.$emit('checkout:postShipping:then', res);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postShippingThen')
            });
        }).catch((err) => {
            state.active = false;
            console.error('checkout:postShipping:catch', err);
            vApp.$emit('checkout:postShipping:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postShippingCatch'),
                err: true
            });
        });
    }
}());

// CardConnect only - remove after WorldPay migration
vApp.$on('cart:postSubmit', function () {
    const state = { active: false };

    return ({ cartName = 'Default' }) => {
        if (_.isNil(cartName)) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('cart:postSubmit');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/cart/submit/${cartName}`,
            type: 'POST',
            contentType: "application/json",
            dataType: 'json'
        }).then((res) => {
            state.active = false;
            console.log('cart:postSubmit:then', res);
            vApp.$emit('cart:postSubmit:then', res);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postSubmitThen')
            });
        }).catch((err) => {
            state.active = false;
            console.error('cart:postSubmit:catch', err);
            vApp.$emit('cart:postSubmit:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postSubmitCatch'),
                err: true
            });
        });
    }
}());

vApp.$on('checkout:postPromo', function () {
    const state = { active: false };
    return ({ promoCode, cartName = 'Default' }) => {
        if (!promoCode) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('cart:postPromo');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/checkout/applyPromo/${promoCode}/${cartName}`,
            type: 'POST',
            contentType: "application/json",
            dataType: 'json'
        }).then((res) => {
            state.active = false;
            console.log('checkout:postPromo:then', res);
            vApp.$emit('checkout:postPromo:then', res);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postPromoThen')
            });
        }).catch((err) => {
            state.active = false;
            console.error('checkout:postPromo:catch', err);
            vApp.$emit('checkout:postPromo:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postPromoCatch'),
                err: true
            });
        });
    }
}());

vApp.$on('checkout:deletePromo', function () {
    const state = { active: false };
    return ({ promoCode, cartName = 'Default' }) => {
        console.log('deletePromo', promoCode)
        if (!promoCode) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('checkout:deletePromo');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/checkout/removepromo/${promoCode}/${cartName}`,
            type: 'DELETE',
            contentType: "application/json",
            dataType: 'json'
        }).then((res) => {
            state.active = false;
            console.log('checkout:deletePromo:then', res);
            vApp.$emit('checkout:deletePromo:then', res);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'deletePromoThen'),
            });
        }).catch((err) => {
            state.active = false;
            console.error('checkout:deletePromo:catch', err);
            vApp.$emit('checkout:deletePromo:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'deletePromoCatch'),
                err: true
            });
        });
    }
}());

// WorldPay Only
vApp.$on('checkout:paymentSource', function () {
    const state = { active: false };
    return ({ data, cartName = 'Default' }) => {
        console.log('paymentSource', cartName)
        if (state.active) { return; }
        state.active = true;
        console.log('checkout:paymentSource');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/checkout/paymentSource/${cartName}`,
            type: 'POST',
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify({
                ...data
            })
        }).then((res) => {
            state.active = false;
            console.log('checkout:paymentSource:then', res);
            vApp.$emit('checkout:paymentSource:then', res);
            vApp.$emit('loadingBar:hide');
        }).catch((err) => {
            state.active = false;
            console.error('checkout:paymentSource:catch', err);
            vApp.$emit('checkout:paymentSource:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'paymentSourceCatch'),
                err: true
            });
        });
    }
}());

// WorldPay Only
vApp.$on('checkout:postPayment', function () {
    const state = { active: false };
    return ({ amount, address, cartName = 'Default' }) => {
        if (!amount) { return; }
        if (!address) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('chekout:postPayment');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/checkout/attachCardPayment/${cartName}`,
            type: 'POST',
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify({
                Amount: amount,
                BillingAddress: address
            })
        }).then((res) => {
            state.active = false;
            console.log('checkout:postPayment:then', res);
            vApp.$emit('checkout:postPayment:then', res);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postPaymentThen')
            });
        }).catch((err) => {
            state.active = false;
            console.error('checkout:postPayment:catch', err);
            vApp.$emit('checkout:postPayment:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postPaymentCatch'),
                err: true
            });
        });
    }
}());

// WorldPay only
vApp.$on('checkout:postPaypal', function () {
    const state = { active: false };
    return ({ orderId, cardHolder, amount, authorization, cartName = 'Default' }) => {
        if (!orderId) {
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postPaypalTokenCatch'),
                err: true
            });
            return;
        }
        if (!amount) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('checkout:postPaypal');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/checkout/attachPayPalPayment/${cartName}`,
            type: 'POST',
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify({
                OrderId: orderId,
                Amount: +amount,
                CardHolder: cardHolder,
                AuthorizationResponse: JSON.stringify(authorization || {})
            })
        }).then((res) => {
            state.active = false;
            console.log('checkout:postPaypal:then', res);
            vApp.$emit('checkout:postPaypal:then', res);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postPaymentThen')
            });
        }).catch((err) => {
            state.active = false;
            console.error('checkout:postPaypal:catch', err);
            vApp.$emit('checkout:postPaypal:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postPaymentCatch'),
                err: true
            });
        });
    }
}());

// WorldPay only
vApp.$on('checkout:postSubmit', function () {
    const state = { active: false };

    return ({ cartName = 'Default' }) => {
        if (_.isNil(cartName)) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('checkout:postSubmit');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/checkout/submit/${cartName}`,
            type: 'POST',
            contentType: "application/json",
            dataType: 'json'
        }).then((res) => {
            state.active = false;
            console.log('checkout:postSubmit:then', res);
            vApp.$emit('checkout:postSubmit:then', res);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postSubmitThen')
            });
        }).catch((err) => {
            state.active = false;
            console.error('checkout:postSubmit:catch', err);
            vApp.$emit('checkout:postSubmit:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'postSubmitCatch'),
                err: true
            });
        });
    }
}());


// My Account Events

vApp.$on('myaccount:getCustomerInfo', function () {
    const state = { active: false };
    return () => {
        if (state.active) { return; }
        state.active = true;
        console.log('myaccount:getCustomerInfo');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/myaccount/getCustomerInfo`,
            type: 'GET',
            contentType: "application/json",
            dataType: 'json'
        }).then((res) => {
            state.active = false;
            console.log('myaccount:getCustomerInfo:then', res);
            vApp.$emit('myaccount:getCustomerInfo:then', res);
            vApp.$emit('loadingBar:hide');
        }).catch((err) => {
            state.active = false;
            console.error('myaccount:getCustomerInfo:catch', err);
            vApp.$emit('myaccount:getCustomerInfo:catch', err);
            vApp.$emit('loadingBar:hide');
        });
    }
}());

vApp.$on('myaccount:saveCustomerInfo', function () {
    const state = { active: false };
    return ({ data }) => {
        if (!data) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('myaccount:saveCustomerInfo');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/myaccount/saveCustomerInfo`,
            type: 'PUT',
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify({
                ...data
            })
        }).then((res) => {
            state.active = false;
            console.log('myaccount:saveCustomerInfo:then', res);
            vApp.$emit('myaccount:saveCustomerInfo:then', res);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'saveCustomerInfoThen')
            });
        }).catch((err) => {
            state.active = false;
            console.error('myaccount:saveCustomerInfo:catch', err);
            vApp.$emit('myaccount:saveCustomerInfo:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'saveCustomerInfoCatch'),
                err: true
            });
        });
    }
}());

vApp.$on('myaccount:getAddresses', function () {
    const state = { active: false };
    return () => {
        if (state.active) { return; }
        state.active = true;
        console.log('myaccount:getAddresses');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/myaccount/getAddresses`,
            type: 'GET',
            contentType: "application/json",
            dataType: 'json'
        }).then((res) => {
            state.active = false;
            console.log('myaccount:getAddresses:then', res);
            vApp.$emit('myaccount:getAddresses:then', res);
            vApp.$emit('loadingBar:hide');
        }).catch((err) => {
            state.active = false;
            console.error('myaccount:getAddresses:catch', err);
            vApp.$emit('myaccount:getAddresses:catch', err);
            vApp.$emit('loadingBar:hide');
        });
    }
}());

vApp.$on('myaccount:saveAddress', function () {
    const state = { active: false };
    return ({ data }) => {
        if (!data) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('myaccount:saveAddress');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/myaccount/saveAddress`,
            type: 'PUT',
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify({
                ...data
            })
        }).then((res) => {
            state.active = false;
            console.log('myaccount:saveAddress:then', res);
            vApp.$emit('myaccount:saveAddress:then', res);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'saveAddressThen')
            });
        }).catch((err) => {
            state.active = false;
            console.error('myaccount:saveAddress:catch', err);
            vApp.$emit('myaccount:saveAddress:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, err.status === 400 ? 'saveAddressValidationCatch' : 'saveAddressCatch'),
                err: true
            });
        });
    }
}());

vApp.$on('myaccount:deleteAddress', function () {
    const state = { active: false };
    return ({ id }) => {
        if (state.active) { return; }
        state.active = true;
        console.log('myaccount:deleteAddress');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/myaccount/deleteAddress/${id}`,
            type: 'DELETE',
            contentType: "application/json",
            dataType: 'json'
        }).then((res) => {
            state.active = false;
            console.log('myaccount:deleteAddress:then', res);
            vApp.$emit('myaccount:deleteAddress:then', res);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'deleteAddressThen')
            });
        }).catch((err) => {
            state.active = false;
            console.error('myaccount:deleteAddress:catch', err);
            vApp.$emit('myaccount:deleteAddress:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'deleteAddressCatch'),
                err: true
            });
        });
    }
}());
vApp.$on('order:getShippingMethods', function () {
    const state = { active: false };
    return () => {
        if (state.active) { return; }
        state.active = true;
        console.log('order:getShippingMethods');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/order/shippingMethods`,
            type: 'GET',
            contentType: "application/json",
            dataType: 'json'
        }).then((res) => {
            state.active = false;
            console.log('order:getShippingMethods:then', res);
            vApp.$emit('order:getShippingMethods:then', res);
            vApp.$emit('loadingBar:hide');
        }).catch((err) => {
            state.active = false;
            console.error('order:getShippingMethods:catch', err);
            vApp.$emit('order:getShippingMethods:catch', err);
            vApp.$emit('loadingBar:hide');
        });
    }
}());

vApp.$on('order:getOrders', function () {
    const state = { active: false };
    return (req) => {
        if (state.active) { return; }
        state.active = true;
        console.log('order:getOrders');
        vApp.$emit('loadingBar:show');
        let qs = '';
        const orderType = _.get(req, 'orderType');
        const serviceStatus = _.get(req, 'serviceStatus');
        if (!_.isNil(orderType)) {
            qs += `?orderType=${orderType}`;
            if (!_.isNil(serviceStatus)) {
                qs += `&serviceStatus=${serviceStatus}`;
            }
        }

        return $.ajax({
            url: `/api/order/list/${_.get(req, 'page', 1)}${qs}`,
            type: 'GET',
            contentType: "application/json",
            dataType: 'json'
        }).then((res) => {
            state.active = false;
            console.log(`order:getOrders${orderType ? ':' + orderType : ''}:then`, res);
            vApp.$emit(`order:getOrders${orderType ? ':' + orderType : ''}:then`, res);
            vApp.$emit('loadingBar:hide');
        }).catch((err) => {
            state.active = false;
            console.error(`order:getOrders${orderType ? ':' + orderType : ''}:catch`, err);
            vApp.$emit(`order:getOrders${orderType ? ':' + orderType : ''}:catch`, err);
            vApp.$emit('loadingBar:hide');
        });
    }
}());

vApp.$on('order:getOrder', function () {
    const state = { active: false };
    return (req) => {
        const pon = _.get(req, 'pon');
        const son = _.get(req, 'son');
        if (!pon && !son) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('order:getOrder');
        vApp.$emit('loadingBar:show');

        return $.ajax({
            url: pon ? `/api/order/${pon}` : `/api/order/service/${son}`,
            type: 'GET',
            contentType: "application/json",
            dataType: 'json'
        }).then((res) => {
            state.active = false;
            console.log('order:getOrder:then', res);
            vApp.$emit('order:getOrder:then', res);
            vApp.$emit('loadingBar:hide');
        }).catch((err) => {
            state.active = false;
            console.error('order:getOrder:catch', err);
            vApp.$emit('order:getOrder:catch', err);
            vApp.$emit('loadingBar:hide');
        });
    }
}());

vApp.$on('order:cancelOrder', function () {
    const state = { active: false };
    return ({ purchaseOrderNumber }) => {
        if (!purchaseOrderNumber) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('order:cancelOrder');
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/order/${purchaseOrderNumber}/cancel`,
            type: 'PUT',
            contentType: "application/json",
            dataType: 'json'
        }).then((res) => {
            state.active = false;
            console.log('order:cancelOrder:then', res);
            vApp.$emit('order:cancelOrder:then', res);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'cancelOrderThen')
            });
        }).catch((err) => {
            state.active = false;
            console.error('order:cancelOrder:catch', err);
            vApp.$emit('order:cancelOrder:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'cancelOrderCatch'),
                err: true
            });
        });
    }
}());

vApp.$on('serviceOrder:offerings', function () {
    const state = { active: false };
    return ({ data }) => {
        if (!data) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('serviceOrder:offerings');
        console.log('data', data);
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/serviceorder/offerings`,
            type: 'POST',
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify({
                ...data
            })
        }).then((res) => {
            state.active = false;
            console.log('serviceOrder:offerings:then', res);
            vApp.$emit('serviceOrder:offerings:then', res);
            vApp.$emit('loadingBar:hide');
        }).catch((err) => {
            state.active = false;
            console.error('serviceOrder:offerings:catch', err);
            vApp.$emit('serviceOrder:offerings:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(err, 'responseJSON.detail', _.get(snackbarCfg, 'offeringsCatch')),
                err: true
            });
        });
    }
}());

vApp.$on('serviceOrder:addMessage', function () {
    const state = { active: false };
    return ({ data, pon }) => {
        if (!data) { return; }
        if (!pon) { return; }
        if (state.active) { return; }
        state.active = true;
        console.log('serviceOrder:addMessage');
        console.log('data', data);
        console.log('pon', pon);
        vApp.$emit('loadingBar:show');
        return $.ajax({
            url: `/api/serviceorder/addmessage/${pon}`,
            type: 'POST',
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify({
                ...data
            })
        }).then((res) => {
            state.active = false;
            console.log('serviceOrder:addMessage:then', res);
            vApp.$emit('serviceOrder:addMessage:then', res);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(snackbarCfg, 'addMessageThen')
            });
        }).catch((err) => {
            state.active = false;
            console.error('serviceOrder:addMessage:catch', err);
            vApp.$emit('serviceOrder:addMessage:catch', err);
            vApp.$emit('loadingBar:hide');
            vApp.$emit('snackbar:show', {
                msg: _.get(err, 'responseJSON.detail', _.get(snackbarCfg, 'addMessageCatch')),
                err: true
            });
        });
    }
}());

vApp.$on('gtm:dataLayer', function () {
    return ({ event, ecommerce }) => {
        console.log('gtm:dataLayer:event', event);
        console.log('gtm:dataLayer:ecommerce', ecommerce);
        const data = {};
        if (event) { data.event = event; }
        if (ecommerce) {

            // Clear existing ecommerce object
            dataLayer.push({ ecommerce: null });

            data.ecommerce = ecommerce;
        }
        dataLayer.push(data);
        console.log('gtm:dataLayer', dataLayer);
    }
}());

vApp.$on('fbq:track', function () {
    return ({ event, options }) => {
        console.log('fbq:track:event', event);
        console.log('fbq:track:options', options);
        fbq('track', event, options);
    }
}());