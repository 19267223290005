import { Html5Qrcode } from "html5-qrcode";
import { isMobile } from "../utilities/is-mobile";
import { initDeviceSize } from "../utilities/device-size";

const { deviceSize } = initDeviceSize();

export function setupReader(HTMLDivElement) {
    const html5QrCode = new Html5Qrcode(/* element id */ HTMLDivElement.id, true);
    let serialNumber = "";
    let hasResult = false;
    const qrCodeSuccessCallback = (decodedText, decodedResult) => {
        /* handle success */
        console.log(`Scan result = ${decodedText}`, decodedResult);
        if (
            decodedText.startsWith("http://") ||
            decodedText.startsWith("https://")
        ) {
            //example serialNumber https://sram.io/a/16T73323397?campgaign=LukeRocks2023
            //example serialNumber https://sram.io/a/16T73323397
            //   example non serialNumber https://sram.io/?campgaign=LukeRocks2023
            //   remove everything after the ?
            const serialNumberdecoded = decodedText.split("?")[0].split("/").pop();
            if (serialNumberdecoded) {
                console.log(`Serial number = ${serialNumber}`);
                serialNumber = serialNumberdecoded;
                // emit serialNumber as event
                const event = new CustomEvent("serialNumber", {
                    detail: serialNumber,
                });
                document.dispatchEvent(event);

                hasResult = true;

                html5QrCode.stop();
            }
        }
    };
    console.log("Aspect Ratio", deviceSize.aspectRatio);
    console.log("height", deviceSize.height);
    console.log("weight", deviceSize.width);
    const config = {
        fps: 10,
        qrbox: { width: 300, height: 300 },
        aspectRatio: 1,
    };
    // If you want to prefer front camera
    const start = () =>
        html5QrCode.start(
            { facingMode: isMobile() ? "environment" : "user" },
            config,
            qrCodeSuccessCallback,
            (error) => {
                console.log(error);
            }
        );
    return {
        start,
        stop: () => html5QrCode.stop(),
        serialNumber: () => serialNumber,
        hasResult: () => hasResult,
    };
}