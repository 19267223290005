import * as _ from 'lodash';

// GA4
vApp.$on('analytics:cart', function () {
	// action must be 'add' or 'remove'
	// cartType must be 'cart' or 'wishlist'
	// items may be a list or single item.
	// Each item should have the properties:
	//  - name: a human-readable name of the item
	//  - modelCode
	//  - fgCode
	//  - price: the item's price before discounts, formatted with currency (eg '$9.99 USD')
	//  - priceDecimal: the item's price before discounts, unformatted, as a string to avoid rounding errors (eg '9.99')
	//  - currencyCode: eg, USD. All items in a single event are assumed to have the same currency code.
	//  - brand: eg, SRAM, QUARQ, etc.
	//  - quantityChange: the quantity added or removed from the cart (eg, if there are five items in the cart and one is removed, quantityChange is 1)
	return ({ action, cartType, items }) => {

		// ensure that we have a list of items, even if we were passed a single item
		const itemsAsList = [].concat(items ?? []);

		if (!itemsAsList.length)
			return;

		const sanitizedCartType = (cartType.toLowerCase() == 'wishlist')
			? 'wishlist'
			: 'cart';

		const event = (action === 'add')
			? `add_to_${sanitizedCartType}`
			: `remove_from_${sanitizedCartType}`;

		vApp.$emit('gtm:dataLayer', {
			event: event,
			ecommerce: {
				currency: itemsAsList[0].currencyCode,
				// the following line has the potential to introduce
				// floating-point rounding errors, but I'm not sure how to avoid
				// them
				value: itemsAsList.reduce((sum, i) => sum + (parseFloat(i.priceDecimal) * i.quantityChange), 0),
				items: itemsAsList.map(i => {
					return {
						item_name: i.name ?? i.fgCode,
						item_id: i.modelCode,
						currency: i.currencyCode,
						price: i.priceDecimal,
						item_brand: i.brand,
						item_category: '',
						item_variant: i.fgCode,
						quantity: i.quantityChange
					}
				})
			}
		});
	}
}());


// Universal Analytics
vApp.$on('analytics:cart', function () {
	// cartType must be 'cart' or 'wishlist'
	// action must be 'add' or 'remove'
	// items may be a list or single item.
	// Each item should have the properties:
	//  - name: a human-readable name of the item
	//  - modelCode
	//  - fgCode
	//  - price: the item's price before discounts, formatted with currency (eg '$9.99 USD')
	//  - priceDecimal: the item's price before discounts, unformatted, as a string to avoid rounding errors (eg '9.99')
	//  - currencyCode: eg, USD. All items in a single event are assumed to have the same currency code.
	//  - brand: eg, SRAM, QUARQ, etc.
	//  - quantityChange: the quantity added or removed from the cart (eg, if there are five items in the cart and one is removed, quantityChange is 1)
	return ({ cartType, action, items }) => {

		// ensure that we have a list of items, even if we were passed a single item
		const itemsAsList = [].concat(items ?? []);

		if (!itemsAsList.length)
			return;

		const sanitizedCartType = (cartType.toLowerCase() == 'wishlist')
			? 'Wishlist'
			: 'Cart';

		var itemMapper = i => {
			return {
				item_name: i.name ?? i.fgCode,
				item_id: i.modelCode,
				currency: i.currencyCode,
				price: i.price,
				item_brand: i.brand,
				item_category: '',
				item_variant: i.fgCode,
				quantity: i.quantityChange
			}
		};

		if (action === 'add') {
			vApp.$emit('gtm:dataLayer', {
				event: `addTo${sanitizedCartType}`,
				ecommerce: {
					currencyCode: itemsAsList[0].currencyCode,
					add: itemsAsList.map(itemMapper)
				}
			});
		} else {
			vApp.$emit('gtm:dataLayer', {
				event: `removeFrom${sanitizedCartType}`,
				ecommerce: {
					currencyCode: itemsAsList[0].currencyCode,
					remove: itemsAsList.map(itemMapper)
				}
			});
		}
	}
}());


// Facebook
vApp.$on('analytics:cart', function () {
	// cartType must be 'cart' or 'wishlist'
	// action must be 'add' or 'remove'
	// items may be a list or single item.
	// Each item should have the properties:
	//  - name: a human-readable name of the item
	//  - modelCode
	//  - fgCode
	//  - price: the item's price before discounts, formatted with currency (eg '$9.99 USD')
	//  - priceDecimal: the item's price before discounts, unformatted, as a string to avoid rounding errors (eg '9.99')
	//  - currencyCode: eg, USD. All items in a single event are assumed to have the same currency code.
	//  - brand: eg, SRAM, QUARQ, etc.
	//  - quantityChange: the quantity added or removed from the cart (eg, if there are five items in the cart and one is removed, quantityChange is 1)
	return ({ cartType, action, items }) => {

		// ensure that we have a list of items, even if we were passed a single item
		const itemsAsList = [].concat(items ?? []);

		if (!itemsAsList.length)
			return;

		if (cartType.toLowerCase() == 'wishlist')
			return;

		if (action === 'add')
			vApp.$emit('fbq:track', { event: 'AddToCart' });
		else if (action === 'remove')
			vApp.$emit('fbq:track', { event: 'RemoveFromCart' });
	}
}());


// Universal Analytics
vApp.$on('analytics:purchase', function () {
	return (order) => {
		vApp.$emit('gtm:dataLayer', {
			event: 'transaction',
			ecommerce: {
				purchase: {
					actionField: {
						id: order.OrderMeta.PurchaseorderNumber,
						revenue: order.Total,
						tax: order.TaxTotal,
						shipping: order.ShippingTotal,
						coupon: order.DiscountTotal
					},
					products: order.Items.map(i => ({
						name: i.DisplayName,
						id: i.ModelCode,
						price: i.LinkedPlacedPrice ?? i.PlacedPrice,
						brand: i.Brand,
						category: '',
						variant: i.Code,
						quantity: i.Quantity
					}))
				}
			}
		});
	}
}());

// GA4
vApp.$on('analytics:purchase', function () {
	return (order) => {
		vApp.$emit('gtm:dataLayer', {
			event: 'purchase',
			ecommerce: {
				transaction_id: order.OrderMeta.PurchaseorderNumber,
				value: order.Total,
				tax: order.TaxTotal,
				shipping: order.ShippingTotal,
				currency: order.Currency,
				discount: order.DiscountTotal,
				items: order.Items.map(i => ({
					item_name: i.DisplayName,
					item_id: i.ModelCode,
					price: i.LinkedPlacedPrice ?? i.PlacedPrice,
					currency: i.Currency,
					item_brand: i.Brand,
					category: '',
					item_variant: i.Code,
					quantity: i.Quantity
				}))
			}
		});
	}
}());

//Facebook
vApp.$on('analytics:purchase', function () {
	return (order) => {
		vApp.$emit('fbq:track', {
			event: 'Purchase',
			options: {
				value: order.Total,
				currency: order.Currency
			}
		});
	}
}());

// Universal Analytics
vApp.$on('analytics:checkout', function () {
	return (cart) => {
		vApp.$emit('gtm:dataLayer', {
			event: 'checkout',
			ecommerce: {
				checkout: {
					actionField: { step: 1, option: 'CHECKOUT' },
					products: cart.Items.map(i => ({
						name: i.DisplayName,
						id: i.ModelCode,
						price: i.PlacedPrice,
						brand: i.Brand,
						category: '',
						variant: i.Code,
						quantity: i.Quantity
					}))
				}
			}
		});
	}
}());

// GA4
vApp.$on('analytics:checkout', function () {
	return (cart) => {
		vApp.$emit('gtm:dataLayer', {
			event: "begin_checkout",
			ecommerce: {
				items: cart.Items.map(i => ({
					item_name: i.DisplayName,
					item_id: i.ModelCode,
					price: i.PlacedPrice,
					item_brand: i.Brand,
					currency: i.Currency,
					item_variant: i.Code,
					quantity: i.Quantity
				}))
			}
		});
	}
}());

// Facebook
vApp.$on('analytics:checkout', function () {
	return (cart) => {
		vApp.$emit('fbq:track', { event: 'InitiateCheckout' });
	}
}());

// GA4
vApp.$on('analytics:checkout:payment', function () {
	return ({ paymentType, cart }) => {
		dataLayer.push({
			event: "add_payment_info",
			ecommerce: {
				currency: cart.Currency,
				value: cart.Total,
				payment_type: paymentType,
				items: cart.Items.map(i => ({
					item_name: i.DisplayName,
					item_id: i.ModelCode,
					price: i.PlacedPrice,
					item_brand: i.Brand,
					currency: i.Currency,
					item_variant: i.Code,
					quantity: i.Quantity
				}))
			}
		});
	}
}());

// GA4
vApp.$on('analytics:checkout:shipping', function () {
	return ({ method, cart }) => {
		vApp.$emit('gtm:dataLayer', {
			event: "add_shipping_info",
			ecommerce: {
				currency: cart.Currency,
				value: cart.Total,
				shipping_tier: method.Name,
				items: cart.Items.map(i => ({
					item_name: i.DisplayName,
					item_id: i.ModelCode,
					price: i.PlacedPrice,
					item_brand: i.Brand,
					currency: i.Currency,
					item_variant: i.Code,
					quantity: i.Quantity
				}))
			}
		});
	}
}());