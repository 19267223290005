const ProductListing = function($element, isSearchPage) {
	this.namespace = 'ProductListing';
	this.$form = $element;
	this.$document = $(document);
	this.$body = $('body');
	this.$header = $('.header');
	this.$container = $('#product-listing-content');
	this.$productList = $('#product-list');
	this.$loadMoreBtn = $('#btn-product-load-more');
	this.$productCountText = $('#product-count');
	this.$productFilters = $('#product-filters');
	this.$breadcrumbs = $('#product-listing-breadcrumbs, #product-listing-filters-selected');
	this.$btnRefine = $('#btn-product-refine');
	this.$filterContainer = $('#product-filter-container');
	this.$clearAllBtn = $('#btn-product-clear-all');
	this.$filterCheckboxes = $element.find('.listing-filters input[type="checkbox"]');
	this.$filterULs = $element.find('.listing-filters-section ul');
	this.$sortBys = $element.find('select.sort-dropdown');
	this.$filterToggles = $element.find('a.listing-filters-toggle');
	this.$mobileCloseBtns = $element.find('#btn-product-filters-close, #btn-product-done-top, #btn-product-done-bottom');

	this.page = 1;
	this.perPage = 15;
	this.implicitFilters = this.$form.data('implicitfilters');
	this.publishingSite = this.$form.data('publishingsite');
	this.serviceSection = this.$form.data('servicesection');
	this.rootFormAction = this.$form.attr('action');
	this.sortByLabel = $(this.$sortBys[0]).data('label');
	this.sort = 'Relevancy';
	this.searchTerm = this.getUrlParameter('t');
	this.baseUrl = location.protocol + '//' + location.host + location.pathname;
	this.isSearchPage = isSearchPage;

	this.cssCheckboxDisabled = 'custom-checkbox--disabled';
	this.cssToggleOpen = 'listing-filters-section-content--open';
	this.cssMenuOpen = 'menu-open';
	this.cssFilterMenuOpen = 'listing-filters--open';
	this.cssAjaxLoading = 'listing--loading';

	this.init();
};

ProductListing.prototype = {
	init: function() {
		const self = this;

		self.$loadMoreBtn.on('click.' + self.namespace, function(e) {
			e.preventDefault();
			self.loadMoreProducts();
		});

		self.$filterCheckboxes.on('click.' + self.namespace, function(e) {
			self.updateFilters();
		});

		self.$sortBys.on('change.' + self.namespace, function(e) {
			const $this = $(this);
			self.updateSort($this.val(), $this.find('option:selected').data('label'));
		});

		self.$filterToggles.on('click.' + self.namespace, function(e) {
			e.preventDefault();
			const $this = $(this);
			const $parent = $this.parent();

			if ($parent.hasClass(self.cssToggleOpen)) {
				$parent.removeClass(self.cssToggleOpen);
				$this.html($this.data('more'));
			} else {
				$parent.addClass(self.cssToggleOpen);
				$this.html($this.data('less'));
			}
		});

		self.$document.on('click.' + self.namespace, 'li.listing-breadcrumbs--tag a', function(e) {
			e.preventDefault();
			const $this = $(this);

			const $checkbox = $('#' + $this.data('filterid'));
			$checkbox.prop('checked', false);
			$this.parent().remove();

			self.updateFilters();
		});

		self.$btnRefine.on('click.' + self.namespace, function(e) {
			e.preventDefault();
			self.$filterContainer.addClass(self.cssFilterMenuOpen);
			self.$filterContainer.css('top', $(window).scrollTop() + 'px');
			self.$body.addClass(self.cssMenuOpen);
			self.$header.css('z-index', '1');
		});

		self.$mobileCloseBtns.on('click.' + self.namespace, function(e) {
			e.preventDefault();
			self.$filterContainer.removeClass(self.cssFilterMenuOpen);
			self.$body.removeClass(self.cssMenuOpen);
			self.$header.css('z-index', '10');
		});

		self.$clearAllBtn.on('click.' + self.namespace, function(e) {
			e.preventDefault();

			self.$filterCheckboxes.prop('checked', false);
			$('li.listing-breadcrumbs--tag').remove();

			self.updateFilters();
		});

		self.$filterULs.each(function() {
			const $this = $(this);

			if ($this.children().length <= 5) {
				$this.css('max-height', 'none');
			} else {
				let height = 0;
				for (let i = 0; i < 5; i++) {
					height = height + $this.children().eq(i).outerHeight(true);
				}
				$this.css('max-height', height + 'px');
			}
		});

		self.$document.on('click.' + self.namespace, '.product-swatches a', function(e) {
			e.preventDefault();
			const $this = $(this);

			if (!$this.hasClass('selected')) {
				const $parent = $this.parents('.product-listing-item');
				$parent.find('.product-swatches a').removeClass('selected');
				$parent.find('.product-listing-item-image').removeClass('product-listing-item-image--selected');
				$parent.find('.product-listing-item-image[data-color="' + $this.data('color') + '"]').addClass('product-listing-item-image--selected');
				$this.addClass('selected');
			}
		});

		const existingFilters = self.getUrlParameter('filters');
		if (existingFilters.length) {
			// we've come back to the page with url params set
			var currPage = parseInt(self.getUrlParameter('page'), 10);
			self.page = (currPage && currPage >= 1) ? currPage : 1;
			self.sort = self.getUrlParameter('sort');

			const filterArray = existingFilters.split(',');

			self.setSelectedFilters(filterArray);

			const state = {
				filters: filterArray,
				sort: self.sort,
				page: self.page,
				searchTerm: self.searchTerm
			};
			self.loadProducts(state, false);
			window.history.replaceState(state, document.title, self.buildStateUrl(state));
		} else {
			// set an initial state
			const state = {
				filters: [],
				sort: self.sort,
				page: self.page,
				searchTerm: self.searchTerm
			};

			if (!self.isSearchPage) {
				window.history.replaceState(state, document.title, self.buildStateUrl(state));
			}
		}

		if (!self.isSearchPage) {
			$(window).on('popstate.' + self.namespace, function(e) {
				const requestData = e.originalEvent.state;
				console.log(requestData);
				self.page = requestData.page;
				self.sort = requestData.sort;
				self.searchTerm = requestData.searchTerm;

				self.setSelectedFilters(requestData.filters);

				// want to create state here to avoid pushing new state
				const state = {
					filters: self.getSelectedFilters(),
					sort: self.sort,
					page: self.page,
					searchTerm: self.searchTerm
				};

				self.loadProducts(state, false);
			});
		}
	},
	loadMoreProducts: function() {
		const self = this;
		self.page = self.page + 1;
		const state = self.getNewState();
		self.loadProducts(state, true);
	},
	updateFilters: function() {
		const self = this;

		self.page = 1;
		const state = self.getNewState();
		self.loadProducts(state, false);
	},
	updateSort: function(value, label) {
		const self = this;

		// update dropdown labels
		self.$sortBys.val('');
		self.$sortBys.find('option:first').html(self.sortByLabel + ' ' + label);

		self.sort = value;
		self.page = 1;
		const state = self.getNewState();
		self.loadProducts(state, false);
	},
	loadProducts: function(state, append) {
		const self = this;

		state.implicitFilters = self.implicitFilters;
		state.publishingSite = self.publishingSite;
		state.serviceSection = self.serviceSection;

		self.$container.addClass(self.cssAjaxLoading);

		$.ajax({
			contentType: 'application/json; charset=utf-8',
			url: self.rootFormAction + '/LoadProducts',
			method: 'GET',
			dataType: 'json',
			traditional: true,
			data: state,
			success: function(data) {
				self.toggleShowMoreButton(data.HasMoreProducts);
				self.$productCountText.html(data.TotalProducts);
				self.updateProductList(data.Products, append);
				self.updateBreadcrumbs(data.SelectedFilterBreadcrumbHtml);

				self.$filterCheckboxes.not(':checked').each(function() {
					const $this = $(this);

					if (!self.arrayIncludes(data.AvailableFilters, $this.attr('id'))) {
						$this.attr('disabled', true);
						$this.parent().addClass(self.cssCheckboxDisabled);
					} else {
						$this.attr('disabled', false);
						$this.parent().removeClass(self.cssCheckboxDisabled);
					}
				});

				setTimeout(function() {
					self.$container.removeClass(self.cssAjaxLoading);
				}, (800));
			},
			error: function(xhr, ajaxOptions, thrownError) {
				console.log(xhr.status);
				console.log(thrownError);
				self.$container.removeClass(self.cssAjaxLoading);
			}
		});
	},
	updateProductList: function(productHtmlArray, append) {
		const self = this;

		if (!append) self.$productList.html('');

		if (productHtmlArray.length) {
			for (let i = 0; i < productHtmlArray.length; i++) {
				if (/Edge\/|Trident\/|MSIE /.test(window.navigator.userAgent)) {
					self.$productList.append(productHtmlArray[i]);
				} else {
					setTimeout(function() {
						self.$productList.append(productHtmlArray[i]);
					}, (50 * i));
				}
			}
		}
	},
	toggleShowMoreButton: function(isOn) {
		const self = this;

		if (isOn) self.$loadMoreBtn.show();
		else self.$loadMoreBtn.hide();
	},
	updateBreadcrumbs: function(html) {
		const self = this;

		self.$breadcrumbs.find('li.listing-breadcrumbs--tag').remove();
		self.$breadcrumbs.append(html);
	},
	getUrlParameter: function(name) {
		name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
		const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
		const results = regex.exec(location.search);
		return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
	},
	setSelectedFilters: function(filterArray) {
		const self = this;

		self.$filterCheckboxes.prop('checked', false);

		for (let i = 0; i < filterArray.length; i++) {
			const $checkBox = self.$filterCheckboxes.filter('[value="' + filterArray[i] + '"]');
			$checkBox.prop('checked', true);
		}
	},
	getSelectedFilters: function() {
		const self = this;

		const selectedFilters = [];
		self.$filterCheckboxes.filter(':checked').each(function() {
			selectedFilters.push($(this).attr('value'));
		});

		return selectedFilters;
	},
	getNewState: function() {
		const self = this;

		const state = {
			filters: self.getSelectedFilters(),
			sort: self.sort,
			page: self.page,
			searchTerm: self.searchTerm
		};

		if (!self.isSearchPage) {
			window.history.pushState(state, document.title, self.buildStateUrl(state));
		}
		return state;
	},
	buildStateUrl: function(state) {
		const self = this;

		let url = self.baseUrl + '?filters=' + state.filters.join(',') + '&sort=' + state.sort + '&page=' + state.page;
		if (state.searchTerm.length) {
			url += '&t=' + state.searchTerm;
		}

		return url;
	},
	arrayIncludes: function(arr, value) {
		for (let i = 0; i < arr.length; i++) {
			if (arr[i] === value) {
				return true;
			}
		}
		return false;
	}
};

$(function() {
	const $element = $('#product-listing-form');
	const isSearchPage = $('#search-results').length != 0;

	if ($element.length != 0) {
		new ProductListing($element, isSearchPage);
	}
});
