const Modal = function(el, options) {
	this.options = $.extend(Modal.defaults, options);
	this.$modal = $(el);
	this.$body = $('body');
	this.contentType = '';
	this.$innerWrapper = this.$modal.find('.inner-wrapper');
	this.closeOnBgClick = this.options.closeOnBgClick;
	this.loading = false;

	this._bindEvents();

	return this;
};

Modal.defaults = {
	triggerSelector: '.modal-trigger',
	closeButtonClass: 'btn-close-modal',
	closeOnBgClick: true,
	transitionSpeed: 300,
	preventAjaxCaching: false,
	centerVertically: false,
};

Modal.prototype = {
	_bindEvents: function() {
		const _self = this;

		_self.$body.on('click', _self.options.triggerSelector, function(e) {
			e.preventDefault();
			_self.$trigger = $(this);

			const target = $(this).attr('href');
			_self.show(target);
			setTimeout(function () {
				let clickedImgId = e.target.id
				const documentElement = document.querySelectorAll(`#${clickedImgId}`)[1]
				documentElement.scrollIntoView()
			}, 20);
		});

		_self.$modal.on('click', '.' + _self.options.closeButtonClass, function(e) {
			e.preventDefault();
			_self.hide();
		});

		_self.$modal.on('click', function(e) {
			if (_self.options.closeOnBgClick && e.target.id == 'modal') {
				_self.hide();
			}
		});

		$(document).keyup(function(e) {
			if (e.keyCode === 27 && _self.$modal.hasClass('show')) {
				_self.hide();
			}
		});

		$(window).resize(function() {
			if (_self._isActive() && _self.options.centerVertically) _self.centerVertically();
		});
	},

	_isActive: function() {
		return this.$modal.hasClass('show');
	},

	disableBgClick: function(bool) {
		this.closeOnBgClick = false;
	},

	enableBgClick: function(bool) {
		this.closeOnBgClick = true;
	},

	show: function(target) {
		const _self = this;

		if (_self._isActive()) {
			_self.hideContent();
		}

		if (!_self.loading) {
			_self.loading = true;
			if (target.substr(0, 1) == '#') {
				_self.loadInlineContent(target);
			} else {
				_self.loadAjaxContent(target);
			}
		}
	},

	_afterShow: function() {
		const _self = this;

		_self.$body.addClass('modal-open');
		_self.$modal.show();
		setTimeout(function() {
			_self.$modal.addClass('show');
		}, 10);
		if (_self.options.centerVertically) _self.centerVertically();
		_self.$modal.$trgger = _self.$trigger;
		_self.$modal.trigger('shown', [_self.$trigger]);
		_self.loading = false;
	},

	hide: function() {
		const _self = this;

		_self.$body.removeClass('modal-open');
		_self.$modal.removeClass('show');
		_self.closeOnBgClick = _self.options.closeOnBgClick;
		_self.$modal.hide();
		_self.hideContent();
		_self.$modal.trigger('hidden');
	},

	hideContent: function() {
		const _self = this;
		if (_self.$modal.data('contentType') === 'inline') {
			_self.$body.append(_self.$innerWrapper.children('.modal-content'));
		} else {
			_self.$innerWrapper.find('.modal-content').remove();
		}
	},

	loadInlineContent: function(target) {
		const _self = this;
		_self.$modal.data('contentType', 'inline');
		_self.$innerWrapper.append($(target));
		_self._afterShow();
	},

	loadAjaxContent: function(target) {
		const _self = this;
		_self.$modal.data('contentType', 'ajax');
		$.ajax({
			url: target,
			dataType: 'html',
			cache: !_self.preventAjaxCaching,
			success: function(data) {
				_self.$innerWrapper.append(data);
				_self._afterShow();
			}
		});
	},

	centerVertically: function() {
		const windowHeight = window.innerHeight;
		const modalHeight = this.$innerWrapper.outerHeight();

		this.$innerWrapper.removeAttr('style');
		if (windowHeight > modalHeight) {
			this.$innerWrapper.css({
				'margin-top': ((windowHeight - modalHeight) / 2) + 'px',
				'max-height': 'none'
			});
		} else {
			this.$innerWrapper.css({
				'max-height': windowHeight + 'px',
				'margin-top': 0
			});
		}
	}
};

$(function() {
	const $modalElement = $('#modal');
	if ($modalElement.length) {
		new Modal($modalElement);
	}
});
