const ProductHighlightCarousel = function($element) {
	this.$element = $element;
	this.classSection = 'product-highlight-carousel-item';
	this.classSectionActive = this.classSection + '--active';
	this.classLink = 'product-highlight-carousel-nav-link';
	this.classLinkActive = this.classLink + '--active';

	this.$allSections = $element.find('.' + this.classSection);
	this.$allLinks = $element.find('.' + this.classLink);
	this.$prevArrow = $element.find('#product-highlight-carousel-sidebar-previous');
	this.$nextArrow = $element.find('#product-highlight-carousel-sidebar-next');
	this.$label = $element.find('#product-highlight-carousel-sidebar-label');

	this.init();
};

ProductHighlightCarousel.prototype = {
	init: function() {
		const self = this;

		self.$allSections.first().addClass(self.classSectionActive);
		self.$allLinks.first().addClass(self.classLinkActive);

		self.$allLinks.on('click', function(e) {
			e.preventDefault();

			const ndx = $('#' + $(this).attr('rel')).index();

			self.setActiveSection(ndx);
		});

		self.$prevArrow.on('click', function() {
			let ndx = self.$allSections.filter('.' + self.classSectionActive).index();
			ndx--;

			if (ndx < 0) ndx = self.$allSections.length - 1;

			self.setActiveSection(ndx);
		});

		self.$nextArrow.on('click', function() {
			let ndx = self.$allSections.filter('.' + self.classSectionActive).index();
			ndx++;

			if (ndx === self.$allSections.length) ndx = 0;

			self.setActiveSection(ndx);
		});
	},
	setActiveSection: function(ndx) {
		const self = this;

		self.$allLinks.removeClass(self.classLinkActive);
		self.$allLinks.eq(ndx).addClass(self.classLinkActive);

		self.$allSections.removeClass(self.classSectionActive);
		self.$allSections.eq(ndx).addClass(self.classSectionActive);

		self.$label.text('0' + (ndx + 1));
	}
};

$(function() {
	const $highlights = $('.product-highlight-carousel');
	if ($highlights.length != 0) {
		$highlights.each(function() {
			new ProductHighlightCarousel($(this));
		});
	}
});
