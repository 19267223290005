const LifeListing = function($element) {
	this.namespace = 'LifeListing';
	this.$form = $element;
	this.$storyList = $('#life-listing-list');
	this.$loadMoreBtn = $('#btn-load-more');

	this.rootFormAction = this.$form.attr('action');
	this.type = this.$form.data('type');
	this.page = 0;

	this.init();
};

LifeListing.prototype = {
	init: function() {
		const self = this;

		self.$loadMoreBtn.on('click.' + self.namespace, function(e) {
			e.preventDefault();
			self.loadMoreStories();
		});
	},
	loadMoreStories: function() {
		const self = this;

		$.ajax({
			contentType: 'application/json; charset=utf-8',
			url: self.rootFormAction + '/LoadStories',
			method: 'GET',
			dataType: 'json',
			traditional: true,
			data: {
				page: (self.page + 1),
				type: self.type
			},
			success: function(data) {
				self.page = self.page + 1;

				self.$storyList.append(data.Stories);
				if (!data.HasMoreProducts) {
					self.$loadMoreBtn.parent().remove();
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				console.log(xhr.status);
				console.log(thrownError);
			}
		});
	},
};

$(function() {
	const $element = $('#life-listing-form');
	if ($element.length != 0) {
		new LifeListing($element);
	}
});
