import * as _ from 'lodash';
import Vue from 'vue';

$(function () {
	const el = '.v-worldpay-checkout';
	const $el = $(el);
	if (!$el.length) {
		return;
	}
	const vCheckout = new Vue({
		el: el,
		name: el,
		data: {
			data: {
				cartName: 'Default',
				steps: ['INFO', 'SHIPPING', 'PAYMENT', 'CONFIRMATION'],
				step: {
					INFO: 2,
					SHIPPING: 3,
					BILLING: 4,
					PAYMENT: 4,
					PAYPAL: 4,
					CONFIRMATION: 5
				},
				methods: [],
				cart: {},
				account: {},
				address: {
					shipping: {},
					billing: {},
					regions: {
						shipping: [],
						billing: []
					}
				},
				order: {}
			},
			state: {
				step: 'INFO',
				fields: {
					errors: {}
				},
				type: 'CC',
				hasDiff: false,
				hasBA: false,
				isEmpty: true
			}
		},
		methods: {
			isComplete: function (step) {
				const isComplete = _.indexOf(this.$data.data.steps, step) < _.indexOf(this.$data.data.steps, this.$data.state.step);
				console.log('isComplete', isComplete);
				return isComplete;
			},
			onInvalid: function (step, $event) {
				console.log('onInvalid');
				this.$data.state.fields.errors = {
					...this.$data.state.fields.errors,
					[$event.target.name]: $event.target.validationMessage
				};
				vApp.$emit('snackbar:show', { msg: _.get(snackbarCfg, 'formOnInvalid'), err: true });
			},
			onChange: function (step, $event) {
				this.$data.state.fields.errors = {
					...this.$data.state.fields.errors,
					[$event.target.name]: ''
				};
			},
			onChangeCountryCode: function (step, $event) {
				console.log('onChangeCountryCode');
				const countryCode = $event.target.value;
				vApp.$on('address:getRegions:then', (res) => {
					vApp.$off('address:getRegions:then');
					console.log('res', res);
					_.set(this.$data.data, `address.regions.${step}`, res || []);
				});
				vApp.$emit('address:getRegions', { countryCode });
			},
			onSubmit: function (step, nextStep, $event) {
				$event.preventDefault();
				const data = _.reduce($($event.target).serializeArray(), (m, i) => {
					_.set(m, i.name, i.value);
					return m;
				}, {});
				console.log('onClickSubmit');
				this.$on('checkout:submitStep:then', () => {
					this.$off('checkout:submitStep:then');
					this.$data.state.step = nextStep;
				});
				this.$emit(`checkout:submitStep`, { step, data });
			},
			onChangeType: function (type, $event) {
				_.set(this.$data, 'state.type', type);
				_.set(this.$data, 'state.hasBA', false);
				_.set(this.$data, 'state.hasDiff', false);
				$('#worldpaydiv').empty();
			},
			onChangeDiff: function (hasDiff, $event) {
				_.set(this.$data, 'state.hasDiff', hasDiff);
				_.set(this.$data, 'state.hasBA', !hasDiff);
				$('#worldpaydiv').empty();
				const $form = $('js-billing-address');
				_.chain(this.$data)
					.get('data.address.billing')
					.each((val, key) => {
						$form.find(`[name=${key}]`).val(val).trigger('blur');
					}).value();
				if(!hasDiff) this.onSubmit('BILLING', 'PAYMENT', $event);
			},
			onClickStep: function (step, $event) {
				$event.preventDefault();
				console.log('onClickStep');
				this.$data.state.step = step;
			},
			onClickRemove: function (item, $event) {
				$event.preventDefault();
				console.log('onClickRemove', this.$data.data.cartName);
				vApp.$emit('checkout:deletePromo', { promoCode: item.Code, cartName: this.$data.data.cartName });
			},
			setupPayment: function (config) {
				// set up WorldPay HPP
				var customOptions = {
					iframeHelperURL: config.HelperURL,
					url: config.PaymentURL,
					type: 'iframe',
					inject: 'immediate',
					target: 'worldpaydiv',
					accessibility: true,
					debug: true,
					language: config.LanguageCode,
					country: config.CountryCode,
					resultCallback: this.onPaymentComplete,
					successURL: '',
					cancelURL: '',
					failureURL: '',
					pendingURL: '',
					errorURL: '',
					customisation: {
						fonts: {
							family: ['Helvetica', 'Arial']
						},
						buttons: {
							color: '#fff',
							backgroundColor: '#e51937',
							border: {
								style: 'solid',
								color: '#e51937',
								width: '1px',
								radius: '0px'
							}
						}
					}
				};
				//initialise the library and pass options
				var libraryObject = new WPCL.Library();
				libraryObject.setup(customOptions);
			},
			onPaymentComplete: function (responseData) {
				var status = responseData.order.status;
				if (status === 'success') {
					this.$on('checkout:submitStep:then', () => {
						this.$off('checkout:submitStep:then');
						this.$data.state.step = 'CONFIRMATION';
					});
					var data = responseData;
					var step = 'PAYMENT'
					this.$emit(`checkout:submitStep`, { step, data });
				} else if (status === 'cancelled_by_shopper') {
					$('#worldpaydiv').empty();
					_.set(this.$data, 'state.hasBA', false);
					_.set(this.$data, 'state.step', 'INFO');
				}
			},

			onTrackCheckout: function () {

				vApp.$emit('analytics:purchase', this.$data.data.order);

				try {
					const href = window.location.href;
					let cfg = {
						merchantUserId: '',
						marketingOptIn: true,
						userEmail: this.$data.data.order.OrderMeta.Email,
						userFirstName: this.$data.data.address.shipping.FirstName,
						userLastName: this.$data.data.address.shipping.LastName,
						orderId: this.$data.data.order.OrderMeta.PurchaseorderNumber,
						orderItems: _.chain(this.$data).get('data.order.Items').map(i => {
							return {
								page_id: i.ModelCode,
								page_id_variant: i.Code, 
								product_name: i.DisplayName,
								quantity: i.Quantity,
								unit_price: i.LinkedTotal || i.Total
							};
						}).value()
					}

					var cfgs = $('[data-powerreviews-payload]').data('powerreviewsPayload');
					cfg = _.chain(cfgs).map(i => {
						return _.chain(i).keys().reduce((m, k) => {
							const _k = k === '_locale' ? k : _.camelCase(k);
							_.set(m, _k, _.get(i, k));
							return m
						}, {}).value();
					}).reduce((m, i) => {
						if (href.indexOf(`/${i._locale}/`) === -1) return m;
						return _.assign({}, i, m);
					}, cfg).value();

					console.log('cfg', cfg);

					const tracker = POWERREVIEWS.tracker.createTracker({
						merchantGroupId: cfg.merchantGroupId
					});

					tracker.trackCheckout(cfg);

				} catch (err) {
					console.log('onTrackCheckout', err);
				}
			}
		},
		mounted: function () {
			var ppenv = $('#checkout').data('ppenv');

			const cartName = _.chain(window.location.search).replace('?', '').split('&').reduce((m, i) => {
				const kv = _.split(i, '=');
				_.set(m, _.get(kv, 0), _.get(kv, 1));
				return m;
			}, {}).get('cartName', 'Default').value();
			this.$data.data.cartName = cartName;
			console.log('cartName', cartName);

			paypal.Button.render({
				// Configure environment
				env: ppenv,
				client: {
					sandbox: 'ARL-sgnfeNVpivMHFfbFNyvxPLdzBMDN4jRt9W4xRzX77pyGzSHoSqnz2wKkdKUODoe-cCg4jCrg6LCf',
					production: 'AWjg2m-xOVy_euyzAJqWc_c8X9s5NuYaHlbl9IDQr9c_Z7Bu7h27JUdM8RstsOQBoZ7h6g8TjJlknDpG'
				},
				// Customize button (optional)
				locale: 'en_US',
				style: {
					size: 'large',
					color: 'gold',
					shape: 'rect'
				},
				funding: {
					disallowed: [paypal.FUNDING.CREDIT],
					disallowed: [paypal.FUNDING.VENMO]
				},

				// Enable Pay Now checkout flow (optional)
				commit: true,

				// Set up a payment
				// payment() is called when the button is clicked
				payment: (data, actions) => {

					// Make a call to the REST api to create the payment
					return actions.payment.create({
						payment: {
							intent: "order",

							payer: {
								"payment_method": "paypal"
							},
							transactions: [
								{
									amount: {
										total: this.$data.data.cart.Total,
										currency: this.$data.data.cart.Currency
									},
									item_list: {
										shipping_address: {
											recipient_name: this.$data.data.address.shipping.FirstName + ' ' + this.$data.data.address.shipping.LastName,
											line1: this.$data.data.address.shipping.Line1,
											line2: this.$data.data.address.shipping.Line2,
											city: this.$data.data.address.shipping.City,
											country_code: this.$data.data.address.shipping.CountryCode,
											postal_code: this.$data.data.address.shipping.PostalCode,
											phone: this.$data.data.address.shipping.DaytimePhoneNumber,
											state: this.$data.data.address.shipping.RegionCode
										}
									}
								}
							],
							application_context: {
								shipping_preference: 'SET_PROVIDED_ADDRESS'
							}
						}
					});
				},

				// Execute the payment
				// onAuthorize() is called when the buyer approves the payment
				onAuthorize: (data, actions) => {
					// Make a call to the REST api to execute the payment
					return actions.payment.execute().then( (data) => {

						//get the paypal order id and payment id
						var resp_id = data.transactions[0].related_resources[0].order.id;

						this.$on('checkout:submitStep:then', () => {
							this.$off('checkout:submitStep:then');
							this.onTrackCheckout();
							this.$data.state.step = 'CONFIRMATION';
						});
						this.$emit('checkout:submitStep', {
							step: 'PAYPAL',
							data: {
								orderId: resp_id,
								cardHolder: data.payer.payer_info.first_name + ' ' + data.payer.payer_info.last_name,
								amount: data.transactions[0].amount.total,
								authorization: data,
								cartName: cartName
							}
						});
					});
				},
				onError: (err) => {
					vApp.$emit('snackbar:show', { msg: _.get(snackbarCfg, 'postPaypalCatch'), err: true });
				}
			}, '#paypal-button-container');


			this.$on('checkout:submitStep:then', () => {
				$("html, body").animate({ scrollTop: 0 });
			});
			this.$on('checkout:submitStep', ({ step, data }) => {
				console.log('checkout:submitStep');
				console.log('step', step);
				switch (step) {
					case 'INFO':
						vApp.$on('checkout:postAddress:then', () => {
							vApp.$off('checkout:postAddress:then');
							vApp.$off('checkout:postAddress:catch');
							this.$emit('checkout:submitStep:then');
						});
						vApp.$on('checkout:postAddress:catch', (err) => {
							vApp.$off('checkout:postAddress:then');
							vApp.$off('checkout:postAddress:catch');
						});
						console.log('data', data);
						_.set(this.$data, 'data.address.shipping', { ...data });
						vApp.$emit('checkout:postAddress', { data, cartName });
						break;
					case 'SHIPPING':
						vApp.$on('checkout:postShipping:then', () => {
							vApp.$off('checkout:postShipping:then');
							vApp.$off('checkout:postShipping:catch');
							vApp.$emit('cart:getCart', { cartName });
							vApp.$emit('fbq:track', { event: 'AddShippingInfo' });
							this.$emit('checkout:submitStep:then');
						});
						vApp.$on('checkout:postShipping:catch', (err) => {
							vApp.$off('checkout:postShipping:then');
							vApp.$off('checkout:postShipping:catch');
						});
						const method = _.find(this.$data.data.methods, i => i.ShippingId == data.Shipping);
						_.set(method, 'selected', true);
						_.set(this.$data, 'data.method', method);
						vApp.$emit('checkout:postShipping', { shippingId: method.ShippingId, cartName });
						vApp.$emit('analytics:checkout:shipping', { method, cart: this.$data.data.cart });
						break;
					case 'PROMO':
						vApp.$on('checkout:postPromo:then', () => {
							vApp.$off('checkout:postPromo:then');
							vApp.$off('checkout:postPromo:catch');
							vApp.$emit('cart:getCart', { cartName });
						});
						vApp.$on('checkout:postPromo:catch', (err) => {
							vApp.$off('checkout:postPromo:then');
							vApp.$off('checkout:postPromo:catch');
						});
						vApp.$emit('checkout:postPromo', { promoCode: data.PromoCode, cartName });
						break;
					case 'BILLING':
						const _ba_data = this.$data.state.hasDiff ? { ...data } : { ...this.$data.data.address.shipping, IsShipping: 'false' };
						_ba_data.Email = this.$data.data.address.shipping.Email;
						data = _ba_data;
						_.set(this.$data, 'data.address.billing', _ba_data);
						_.set(this.$data, 'state.hasBA', true);

						vApp.$on('checkout:paymentSource:then', (res) => {
							vApp.$off('checkout:paymentSource:then');
							this.setupPayment(res);
						});
						vApp.$emit('checkout:paymentSource', { data, cartName });


						break;
					case 'PAYMENT':
						vApp.$on('checkout:postAddress:then', () => {
							vApp.$off('checkout:postAddress:then');
							vApp.$off('checkout:postAddress:then');
							this.$emit('checkout:submitStep:then');
						});
						vApp.$on('checkout:postAddress:catch', (err) => {
							vApp.$off('checkout:postAddress:then');
							vApp.$off('checkout:postAddress:catch');
						});

						vApp.$on('checkout:postPayment:then', () => {
							vApp.$off('checkout:postPayment:then');
							vApp.$off('checkout:postPayment:catch');
							vApp.$emit('fbq:track', { event: 'AddPaymentInfo' });
							vApp.$emit('checkout:postSubmit', { cartName });
						});
						vApp.$on('checkout:postPayment:catch', (err) => {
							vApp.$off('checkout:postPayment:then');
							vApp.$off('checkout:postPayment:catch');
						});
						vApp.$on('checkout:postSubmit:then', (res) => {
							vApp.$off('checkout:postSubmit:then');
							vApp.$off('checkout:postSubmit:catch');
							this.$data.data.order = { ...res };
							this.onTrackCheckout();
							this.$emit('checkout:submitStep:then');
						});
						vApp.$on('checkout:postSubmit:catch', (err) => {
							vApp.$off('checkout:postSubmit:then');
							vApp.$off('checkout:postSubmit:catch');
						});
						const _data = this.$data.state.hasDiff ? { ...this.$data.data.address.billing } : { ...this.$data.data.address.shipping, IsShipping: 'false' };
						_.set(this.$data, 'data.address.billing', _data);

						vApp.$emit('checkout:postPayment', {
							amount: this.$data.data.cart.Total,
							address: _data,
							cartName
						});
						vApp.$emit('analytics:checkout:payment', { paymentType: 'Credit Card', cart: this.$data.data.cart });
						break;
					case 'PAYPAL':
						vApp.$on('checkout:postPaypal:then', () => {
							vApp.$off('checkout:postPaypal:then');
							vApp.$off('checkout:postPaypal:catch');
							vApp.$emit('fbq:track', { event: 'AddPaymentInfo' });
							vApp.$emit('checkout:postSubmit', { cartName });
						});
						vApp.$on('checkout:postPaypal:catch', (err) => {
							vApp.$off('checkout:postPaypal:then');
							vApp.$off('checkout:postPaypal:catch');
						});
						vApp.$on('checkout:postSubmit:then', (res) => {
							vApp.$off('checkout:postSubmit:then');
							vApp.$off('checkout:postSubmit:catch');
							this.$data.data.order = { ...res };
							this.$emit('checkout:submitStep:then');
							this.trackCheckout();
						});
						vApp.$on('checkout:postSubmit:catch', (err) => {
							vApp.$off('checkout:postSubmit:then');
							vApp.$off('checkout:postSubmit:catch');
						});
						vApp.$emit('checkout:postPaypal', { ...data, cartName });
						vApp.$emit('analytics:checkout:payment', { paymentType: 'PayPal', cart: this.$data.data.cart });
						break;
				};

				// Universal Analytics
				vApp.$emit('gtm:dataLayer', {
					event: 'checkout',
					ecommerce: {
						checkout: {
							actionField: { step: this.$data.data.step[step], option: step },
							products: this.$data.data.cart.Items.map(i => ({
								name: i.DisplayName,
								id: i.ModelCode,
								price: i.PlacedPrice,
								brand: i.Brand,
								category: '',
								variant: i.Code,
								quantity: i.Quantity
							}))
						}
					}
				});
			});

			vApp.$on('checkout:deletePromo:then', (res) => {
				vApp.$emit(`cart:getCart:${cartName}:then`, res);
			});

			vApp.$on(`cart:getCart:${cartName}:then`, (cart) => {
				console.log('cart:getCart:then', cart);
				_.set(this.$data, 'data.cart', { ...cart });
				_.set(this.$data, 'state.isEmpty', !cart.TotalItems);
			});

			vApp.$on('order:getShippingMethods:then', (methods) => {
				console.log('order:getShippingMethods:then', methods);
				if (!methods) { return; }
				_.set(this.$data, 'data.methods', [...methods]);
			});

			vApp.$on('myaccount:getCustomerInfo:then', (res) => {
				_.set(this.$data, 'data.account', { ...res });
				if (!res.Email) { return; }
				const $form = $(`.js-shipping-address`);
				const $field = $form.find(`[name=Email]`);
				$field.prop('readonly', true);
				$field.val(res.Email).trigger('blur');
			});

			vApp.$on('checkout:getAddress:then', (address) => {
				console.log('checkout:getAddress:then', address);
				const countryCode = $(`[name=CountryCode]`).val();
				if (address && (!address.IsShipping || (countryCode === address.CountryCode))) {
					_.set(this.$data.data, `address.${address.IsShipping ? 'shipping' : 'billing'}`, address);
					const $form = $(`.js-${address.IsShipping ? 'shipping' : 'billing'}-address`);
					_.each(address, (val, key) => {
						if (key === 'Email' && this.$data.data.account.Email) { return; }
						$form.find(`[name=${key}]`).val(val).trigger('blur');
					});
				}
				vApp.$on('address:getRegions:then', (res) => {
					vApp.$off('address:getRegions:then');
					console.log('res', res);
					_.set(this.$data.data, `address.regions.${(countryCode || address.IsShipping) ? 'shipping' : 'billing'}`, res || []);
					_.delay(() => $(`${address.IsShipping ? '#regioncode' : '#regioncode-2'}`).val(address.RegionCode), 0);
				});
				vApp.$emit('address:getRegions', { countryCode: countryCode || address.CountryCode });
			});

			vApp.$emit('cart:getCart', { cartName });
			vApp.$emit('order:getShippingMethods');
			vApp.$emit('myaccount:getCustomerInfo');
			vApp.$emit('checkout:getAddress', { isShipping: true });
			vApp.$emit('checkout:getAddress', { isShipping: false });
		},
		beforeDestroyed: function () {
			$(window).off('resize');

			window.removeEventListener('message');

			vApp.$off('cart:getCart:then');
			vApp.$off('order:getShippingMethods:then');
			vApp.$off('myaccount:getCustomerInfo:then');
			vApp.$off('checkout:getAddress:then');
		}
	});
	global.vCheckout = vCheckout;
});