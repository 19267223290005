const PageNavigation = function (element) {

	this.linkContainer = element.querySelector('ul.page-navigation-links');
	this.linkContainerDropdownToggle = element.querySelector('div.page-navigation-collpase');

	this.navContainer = element;

	this.initialPosition = 0;
	this.mobile = false;

	// an Array of the links in the nav header
	this.navLinks = [];

	// a NodeList of the sections represented in the nav header
	this.navSections = [];

	this.init();
};

PageNavigation.prototype = {
	init: function() {
		const self = this;

		[self.navLinks, self.navSections] = self.buildNavLinks();

		document.addEventListener('resize', () => {
			self.checkLinkStatus();
			self.initialPosition = self.navContainer.offsetTop;
		});

		document.addEventListener('scroll', () => {
			self.checkLinkStatus();
		});

		self.linkContainerDropdownToggle.addEventListener('click', function () {
			self.toggleMobileNav();
		});

		enquire.register('screen and (max-width:1024px)', {
			match: function() {
				self.mobile = true;
			},
			unmatch: function() {
				self.mobile = false;

				self.collapseMobileNav();
			}
		});

		self.initialPosition = self.navContainer.offsetTop;

		self.checkLinkStatus();
	},
	checkLinkStatus: function() {
		const self = this;

		// re-set any existing 'active' style
		self.navLinks.forEach(l => l.classList.remove('active'));

		// Working from the bottom of the page up, pick the first section that's
		// top is at least 100px from the bottom of the nav. 100px is an
		// arbitrary number that felt good.
		for (const section of Array.from(self.navSections).reverse()) {

			// If an element is hidden, don't consider it for highlighting
			// offsetParent is null when the element is hidden in the DOM
			if (!section.offsetParent)
				continue;

			if (section.getBoundingClientRect().top <= 100) {
				self.linkContainer
					.querySelector(`a[data-section-number='${section.dataset.sectionNumber}']`)
					.classList
					.add('active');

				return;
			}
		}

		// In the mobile layout, we need to make sure a section link is always
		// active or the section picker widget will disappear. If no section is
		// in view, default to highlighting the first.
		if (self.mobile) {
			self.navLinks[0].classList.add('active');
		}
	},
	toggleMobileNav: function () {
		const self = this;

		self.linkContainer.classList.toggle('page-navigation-links--expand');
		self.linkContainerDropdownToggle.classList.toggle('page-navigation-collpase--expand');
	},
	collapseMobileNav: function () {
		const self = this;

		self.linkContainerDropdownToggle.classList.remove('page-navigation-collpase--expand');
		self.linkContainer.classList.remove('page-navigation-links--expand');
    },
	buildNavLinks: function () {
		const self = this;
		const sections = document.querySelectorAll('div[data-nav-name]');

		const dealerLocatorLink = self.linkContainer.querySelector('.page-navigation-links--cta');

		let links = [];

		sections.forEach((el, i) => {
			const navName = el.dataset.navName;

			let link = document.createElement('a');
			link.setAttribute('title', navName);
			link.setAttribute('href', '#');
			link.text = navName;

			el.dataset.sectionNumber = i;
			link.dataset.sectionNumber = i;

			link.addEventListener('click', e => {
				e.preventDefault();

				const target = document.querySelector(`div[data-section-number='${e.target.dataset.sectionNumber}']`);

				console.log(target);

				if (target) {
					window.scrollTo({
						top: target.getBoundingClientRect().top + window.pageYOffset - self.navContainer.clientHeight,
						behavior: 'smooth'
					});

					if (self.mobile) {
						self.collapseMobileNav();
					}
				}
			});

			if (el.dataset.navClass) {
				el.dataset.navClass.split(" ").forEach(c => link.classList.add(c));
			}

			let li = document.createElement('li');
			li.appendChild(link);

			self.linkContainer.insertBefore(li, dealerLocatorLink);
			links.push(link);
		});

		return [links, sections];
	}
};

(function() {
	const el = document.querySelector('#page-navigation');

	if (el) {
		new PageNavigation(el);
	}
})();
