/**
 * Toggles the visibility of a given HTML element.
 * 
 * @param {HTMLElement} element - The HTML element to toggle.
 * @param {boolean} shouldShow - A flag indicating whether to show or hide the element.
 */
export function toggleElement(element, shouldShow) {
    // Basic validation to ensure that 'element' is indeed an instance of an HTMLElement
    if (!(element instanceof HTMLElement)) {
        console.error('toggleElement: Provided element is not an instance of HTMLElement.');
        return;
    }
    // Ensuring 'shouldShow' is a boolean, if not, coerce it.
    if (typeof shouldShow !== 'boolean') {
        console.warn('toggleElement: shouldShow is not a boolean. Coercing its value.');
        shouldShow = Boolean(shouldShow);
    }

    // Toggle the 'hide' class based on the 'shouldShow' parameter
    element.classList.toggle('hide', !shouldShow);

    // Update 'aria-hidden' attribute for accessibility
    element.setAttribute('aria-hidden', !shouldShow);

}