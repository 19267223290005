import * as _ from 'lodash';
import Vue from 'vue';

$(function () {
	const el = '.v-snackbar';
	const $el = $(el);
	if (!$el.length) {
		return;
	}
	const vSnackbar = new Vue({
		el: el,
		name: el,
		data: {
			data: {
				msg: ''
			},
			state: {
				show: false,
				error: false
			}
		},
		methods: {

		},
		mounted: function () {
			vApp.$on('snackbar:show', ({ msg, err }) => {
				this.$data.data.msg = msg || _.get(snackbarCfg, 'fallbackMsg');
				this.$data.state.err = err;
				this.$data.state.show = true;
				_.delay(() => this.$data.state.show = false, 3000);
			});
		},
		beforeDestroyed: function () {
			vApp.$off('snackbar:show');
		}
	});

	global.vSnackbar = vSnackbar;
});