const ImageGallery = function($element) {
	this.$element = $element;

	this.classImg = 'image-gallery-img';
	this.classImgActive = this.classImg + '--active';
	this.classNav = 'image-gallery-navigation-item';
	this.classNavActive = this.classNav + '--active';

	this.$imgs = this.$element.find('.' + this.classImg);
	this.$thumbnails = this.$element.find('.' + this.classNav);

	this.init();
};

ImageGallery.prototype = {
	init: function() {
		const self = this;

		self.$thumbnails.on('click touchend', function(e) {
			e.preventDefault();
			const $this = $(this);

			const ref = $this.attr('rel');
			self.$imgs.removeClass(self.classImgActive);
			self.$element.find('#' + ref).addClass(self.classImgActive);
			self.$thumbnails.removeClass(self.classNavActive);
			$this.addClass(self.classNavActive);
		});
	}
};

$(function() {
	const $gallerys = $('.image-gallery');
	if ($gallerys.length != 0) {
		$gallerys.each(function() {
			new ImageGallery($(this));
		});
	}
});
