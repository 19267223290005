import { setupReader } from "./reader-detailed.js";
import { toggleElement } from "../utilities/toggle-element.js";

function query(selector) {
  const element = document.querySelector(selector);
  if (!element) {
    console.error(`No element found for selector: ${selector}`);
  }
  return element;
}

if (document.querySelector('#reader')){
    const { start, stop } = setupReader(query("#reader"));
    const qrButton = query("#startQrScannerButton");
    const serialNumberElement = query("#serialNumberInput");
    const cancelButton = query("#close-qr-reader-btn");
    const retrieveProdInfoBtn = query("#getProductInfoBtn");
    const inputContainer = query("#serial-number-container");
    const qrContainer = query("#qrcode-scanner-modal");

    if (
        serialNumberElement &&
        qrButton &&
        retrieveProdInfoBtn &&
        inputContainer &&
        qrContainer
    ) {
        document.addEventListener("serialNumber", (event) => {
            const serialNumberFromEvent = event.detail;
            console.log(`Serial number = ${serialNumberFromEvent}`);
            serialNumberElement.value = serialNumberFromEvent;
            toggleElement(qrButton, true);
            toggleElement(serialNumberElement, false);
            retrieveProdInfoBtn.removeAttribute("disabled");
            toggleElement(inputContainer, true);
            toggleElement(qrContainer, false);
        });
    }

    if (qrButton) {
        qrButton.addEventListener("click", () => {
            console.log("QR Started...");
            start();
            serialNumberElement.value = "";
            toggleElement(qrButton, false);
            toggleElement(serialNumberElement, false);
            toggleElement(inputContainer, false);
            toggleElement(qrContainer, true);
            retrieveProdInfoBtn.setAttribute("disabled", true);
        });
    }

    if (cancelButton) {
        cancelButton.addEventListener("click", () => {
            toggleElement(qrButton, true);
            toggleElement(serialNumberElement, true);
            serialNumberElement.value = "";
            toggleElement(inputContainer, true);
            toggleElement(qrContainer, false);
            retrieveProdInfoBtn.setAttribute("disabled", true);
            stop();
        });
    }
}
