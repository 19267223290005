const SearchResults = function($element) {
	this.namespace = 'SearchResults';
	this.$element = $element;

	this.$tabTriggers = $('#search-results-tabs').find('a');
	this.$allTabs = $('#product-listing-content, #service-listing-content, #site-listing-content');

	this.init();
};

SearchResults.prototype = {
	init: function() {
		const self = this;

		self.$tabTriggers.on('click.' + self.namespace, function(e) {
			e.preventDefault();
			const $this = $(this);

			const target = $this.attr('rel');

			self.$allTabs.hide();
			self.$tabTriggers.removeClass('active');

			$('#' + target).show();
			$this.addClass('active');
		});
	},
};

$(function() {
	const $elements = $('#search-results');
	if ($elements.length != 0) {
		new SearchResults($(this));
	}
});
