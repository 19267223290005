const NewsletterInline = function ($element) {
    this.namespace = 'NewsletterInline';
    this.$container = $element;
    this.$submitBtn = $('#newsletter-inline-submit');
    this.$emailBox = $('#newsletter-inline-email');
    this.$newsletterId = $('#newsletter-inline-newsletterId');
    this.$messaging = $('#newsletter-inline-messaging');
    this.$postSubmitLandingPage = $('#newsletter-inline-post-submit-landing-page');

    this.emailRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    this.cssClassError = 'footer-newsletter-message--error';

    this.init();
};

NewsletterInline.prototype = {
    init: function () {

        const self = this;

        self.$submitBtn.on('click.' + self.namespace, function (e) {
            e.preventDefault();
            self.submit();
        });
    },
    submit: function () {
        const self = this;
        const email = self.$emailBox.val();
        const newsletterId = self.$newsletterId.val();

        self.hideMessaging();

        if (self.isEmail(email)) {

            $.ajax({
                contentType: 'application/json; charset=utf-8',
                url: '/api/newsletter/subscribe',
                method: 'POST',
                dataType: 'json',
                traditional: true,
                data: JSON.stringify({
                    email: email,
                    newsletterId: [ newsletterId ],
                }),
                success: function (data) {
                    if (data.Success) {
                        window.location.href = self.$postSubmitLandingPage.val();
                    } else {
                        self.showMessaging(data.Message, true);
                    }
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    console.log(xhr.status);
                    console.log(thrownError);
                    self.showMessaging('An error has occured with your submission. Please try again later.', true);
                }
            });
        } else {
            self.showMessaging('Please enter a valid email address.', true);
        }
    },
    showMessaging: function (msg, isError) {
        const self = this;

        self.$messaging.html(msg);
        if (isError) self.$messaging.addClass(self.cssClassError);
        else self.$messaging.removeClass(self.cssClassError);

        self.$messaging.show();
    },
    hideMessaging: function () {
        const self = this;

        self.$messaging.hide();
    },
    isEmail: function (email) {
        return this.emailRegex.test(email);
    }
};

$(function () {
    const $element = $('#newsletter-signup-inline');

    if ($element.length != 0) {
        new NewsletterInline($element);
    }
});
