import * as _ from 'lodash';
import Vue from 'vue';

$(function () {
	const el = '.v-account';
	const $el = $(el);
	if (!$el.length) {
		return;
	}
	const vAccount = new Vue({
		el: el,
		name: el,
		data: {
			data: {
				order: {},
				orders: {},
				serviceOrders: {},
				cart: {},
				account: {},
				addresses: [],
				listAddresses: [],
				regions: [],
				currentCountryCode: '',
				offerings: [],
				proofOfPurchase: {},
				productImages: [],
				genericPart: '',
				serialFGCode: '',
				serialModelCode: '',
				serialModelName: ''
			},
			state: {
				view: 'OVERVIEW',
				addressView: 'LIST',
				requestStep: 1,
				serialHelp: false,
				fields: {
					errors: {}
				}
			}
		},
		methods: {
			onInvalid: function (action, $event) {
				console.log('onInvalid');
				this.$data.state.fields.errors = {
					...this.$data.state.fields.errors,
					[$event.target.name]: $event.target.validationMessage
				};
				vApp.$emit('snackbar:show', { msg: _.get(snackbarCfg, 'formOnInvalid'), err: true });
			},
			onChange: function (action, $event) {
				console.log('action', action);
				const $form = $($event.target);
				const data = _.reduce($form.serializeArray(), (m, i) => {
					_.set(m, i.name, i.value);
					return m;
				}, {});
				console.log('data', data);

				switch(action) {
					case 'REQUESTS':
					this.$data.data.serviceStatus = data.ServiceStatus || 'ALL';
					vApp.$emit('order:getOrders', {
						orderType: 'ServiceOrder',
						serviceStatus: this.$data.data.serviceStatus
					});
					break;
				}
				this.$data.state.fields.errors = {
					...this.$data.state.fields.errors,
					[$event.target.name]: ''
				};
			},
			onSubmit: function (action, $event) {
				$event.preventDefault();
				const $form = $($event.target);
				const data = _.reduce($form.serializeArray(), (m, i) => {
					_.set(m, i.name, i.value);
					return m;
				}, {});
				console.log('onClickSubmit');
				switch (action) {
					case 'ADDRESS':
						vApp.$on('myaccount:saveAddress:then', () => {
							this.$off('myaccount:saveAddress:then');
							vApp.$emit('myaccount:getAddresses');
							this.$data.state.addressView = 'LIST';
						});
						vApp.$emit(`myaccount:saveAddress`, { data });
						break;
					case 'ACCOUNT':
						vApp.$on('myaccount:saveCustomerInfo:then', () => {
							this.$off('myaccount:saveCustomerInfo:then');
							vApp.$emit('myaccount:getCustomerInfo');
							this.$data.state.view = 'ADDRESSES'
							this.$data.state.addressView = 'LIST';
						});
						vApp.$emit(`myaccount:saveCustomerInfo`, { data });
						break;
					case 'REQUEST':
						if (this.$data.state.requestStep === 2) {
							vApp.$on('cart:putServiceCart:then', () => {
								this.$off('cart:putServiceCart:then');
								this.$data.state.requestStep = 3;
							});
							vApp.$on('cart:putServiceCart:catch', () => {
								this.$off('cart:putServiceCart:catch');
							});
							return vApp.$emit('cart:putServiceCart', {
 								skuCode: data.Code,
								problemDescription: data.Description,
								serialNumber: data.Serial,
								genericPart: this.$data.data.genericPart,
								serialFGCode: this.$data.data.serialFGCode,
								serialModelCode: this.$data.data.serialModelCode,
								serialModelName: this.$data.data.serialModelName
							});
						}
						if (this.$data.state.requestStep === 3) {
							vApp.$on('cart:putServiceCartImages:then', () => {
								this.$off('cart:putServiceCartImages:then');
								window.location.href = `${$('.v-account').data('checkoutpath')}?cartName=Service`;
							});
							vApp.$on('cart:putServiceCartImages:catch', () => {
								this.$off('cart:putServiceCartImages:catch');
							});
							const formData = new FormData($form.get(0));
							formData.delete('Serial');
							formData.delete('Description');
							console.log('formData', formData);
							const hasProofOfPurchase = !_.isEmpty($form.find('[name=ProofOfPurchase]').get(0).files);
							const hasProductImages = !_.isEmpty($form.find('[name=ProductImages]').get(0).files);
							!hasProofOfPurchase && formData.delete('ProofOfPurchase');
							!hasProductImages && formData.delete('ProductImages');
							if (!hasProofOfPurchase && !hasProductImages) {
								return window.location.href = `${$('.v-account').data('checkoutpath')}?cartName=Service`;
							}
							return vApp.$emit('cart:putServiceCartImages', { data: formData });
						}
						vApp.$on('serviceOrder:offerings:then', (res) => {
							this.$off('serviceOrder:offerings:then');
							console.log('requestStep', this.$data.state.requestStep);
							this.$data.data.offerings = res.Offerings;
							this.$data.data.genericPart = res.GenericPart;
							this.$data.data.serialFGCode = res.SerialFGCode;
							this.$data.data.serialModelCode = res.SerialModelCode;
							this.$data.data.serialModelName = res.SerialModelName;

							this.$data.state.requestStep = 2;
						});
						vApp.$on('serviceOrder:offerings:catch', (err) => {
							this.$off('serviceOrder:offerings:catch');
							if (err.responseJSON.title === 'INVALID_SERIAL') this.$data.state.serialHelp = true;
							else this.$data.state.serialHelp = false;
						});
						vApp.$emit(`serviceOrder:offerings`, { data });
						break;
					case 'REQUEST_DETAIL':
						const pon = _.get(this.$data, 'data.order.OrderMeta.PurchaseorderNumber');
						vApp.$on('serviceOrder:addMessage:then', (res) => {
							this.$off('serviceOrder:addMessage:then');
							this.onClickDetail({ PurchaseorderNumber: pon }, null, 'REQUEST_DETAIL');
						});
						vApp.$on('serviceOrder:addMessage:catch', () => {
							this.$off('serviceOrder:addMessage:catch');

						});
						vApp.$emit(`serviceOrder:addMessage`, { data, pon });
						break;
				}
			},
			onClickView: function (view, $event, addressView, address) {
				$event.preventDefault();
				console.log('onClickView');
				this.$data.state.view = view;
				console.log($event.target);

				(view === 'ORDERS' || view === 'REQUESTS') && vApp.$emit('order:getOrders', {
					orderType: view === 'REQUESTS' ? 'ServiceOrder' : undefined,
					serviceStatus: view === 'REQUESTS' ? 'ALL' : undefined
				});

				_.delay(() => $('html, body').animate({
					scrollTop: $(`#header`).offset().top
				}, 500, 'linear'), 0);
				_.delay(() => this.onClickAccount(), 0);

				(addressView && address) && _.delay(() => this.onClickAddress(addressView, $event, address), 0);

			},
			onClickPage: function (item, $event) {
				$event.preventDefault();
				console.log('onClickPage', item);
				const view = this.$data.state.view;
				const orders = view === 'REQUESTS' ? this.$data.data.serviceOrders : this.$data.data.orders;
				let page = orders.Page;
				switch (item) {
					case 'PREV':
						page = page > 1 ? (page - 1) : page;
						break;
					case 'NEXT':
						page = page >= orders.PageTotal ? page : (page + 1);
						break;
					default:
						page = item;
						break;
				}
				if (orders.Page === page) { return; }
				vApp.$emit('order:getOrders', {
					page,
					orderType: view === 'REQUESTS' ? 'ServiceOrder' : undefined,
					serviceStatus: view === 'REQUESTS' ? this.$data.data.serviceStatus : undefined
				});
			},
			onClickDetail: function (item, $event, view = 'ORDER_DETAIL') {
				$event && $event.preventDefault();
				vApp.$on('order:getOrder:then', (res) => {
					console.log('order:getOrder:then', res);
					vApp.$off('order:getOrder:then');
					res.OrderMeta = _.chain(res).get('OrderMeta').thru(i => {
						return {
							...i,
							PurchaseDate: (new Date(i.PurchaseDate)).toLocaleDateString()
						};
					}).value();
					res.ServiceImages = _.chain(res).get('ServiceImages').thru(i => {
						if (!i) { return; }
						return {
							...i,
							ProofOfPurchase: i.ProofOfPurchase && `/api/order/serviceimages/${res.OrderMeta.PurchaseorderNumber}/${i.ProofOfPurchase}`,
							Image1: i.Image1 && `/api/order/serviceimages/${res.OrderMeta.PurchaseorderNumber}/${i.Image1}`,
							Image2: i.Image2 && `/api/order/serviceimages/${res.OrderMeta.PurchaseorderNumber}/${i.Image2}`,
							Image3: i.Image3 && `/api/order/serviceimages/${res.OrderMeta.PurchaseorderNumber}/${i.Image3}`,
						}
					}).value();
					res.Communication = _.chain(res).get('Communication').map(i => {
						if (!i) { return; }
						return {
							...i,
							date: (new Date(i.date)).toLocaleDateString(),
							time: (new Date(`${i.date} ${i.time}`)).toLocaleTimeString()
						}
					}).value();
					this.$data.data.order = { ...res };
					this.$data.state.view = view;
				});
				const req = {};
				_.set(req, view === 'REQUEST_DETAIL' ? 'son' : 'pon', item.PurchaseorderNumber);
				vApp.$emit('order:getOrder', req)
			},
			onClickRemove: function (item, $event) {
				$event.preventDefault();
				vApp.$on('myaccount:deleteAddress:then', () => {
					vApp.$emit('myaccount:getAddreses');
				});
				vApp.$emit('myaccount:deleteAddress', { id: item.Id });
			},
			onClickDefault: function (item, $event) {
				$event.preventDefault();
				vApp.$on('myaccount:saveAddress:then', () => {
					this.$off('myaccount:saveAddress:then');
					vApp.$emit('myaccount:getAddresses');
				});
				item.IsDefault = true;
				vApp.$emit('myaccount:saveAddress', { data: item });
			},
			onClickTransferCart: function ($event) {
				$event.preventDefault();
				console.log('onClickTransferCart');
				vApp.$emit('cart:transferCart');

				const items = _.chain(this.$data).get('data.cart.Items').map(i => {
					return {
						name: i.DisplayName,
						modelCode: i.ModelCode,
						fgCode: i.Code,
						price: i.LinkedPlacedPriceFormatted ?? i.PlacedPriceFormatted,
						priceDecimal: i.LinkedPlacedPrice ?? i.PlacedPrice,
						brand: i.Brand,
						quantityChange: i.Quantity
					};
				}).value();

				vApp.$emit('analytics:cart', { action: 'add', cartType: 'cart', items: items });
				vApp.$emit('analytics:cart', { action: 'remove', cartType: 'wishlist', items: items });
			},
			onClickTransferLine: function (item, $event) {
				$event.preventDefault();
				console.log('onClickTransferLine');
				vApp.$emit('cart:transferLine', { skuCode: item.Code });
				vApp.$emit('cart:getCartItemCount', { cartName: 'Wishlist' });

				const items = {
					name: item.DisplayName,
					modelCode: item.ModelCode,
					fgCode: item.Code,
					price: item.LinkedPlacedPriceFormatted ?? item.PlacedPriceFormatted,
					priceDecimal: item.LinkedPlacedPrice ?? item.PlacedPrice,
					brand: item.Brand,
					quantityChange: item.Quantity
				};

				vApp.$emit('analytics:cart', { action: 'add', cartType: 'cart', items: items });
				vApp.$emit('analytics:cart', { action: 'remove', cartType: 'wishlist', items: items });
			},
			onClickCartRemove: function (item, $event) {
				$event.preventDefault();
				const skuCodes = _.chain(item).get('LinkedItems').reduce((m, i) => m.concat(i.Code), [item.Code]).value();
				vApp.$emit('cart:putCart', { skuCode: item.Code, skuCodes, modelCode: item.ModelCode, linkId: item.LinkId, qty: 0, cartName: 'Wishlist' });
				vApp.$emit('cart:getCartItemCount', { cartName: 'Wishlist' });

				vApp.$emit('analytics:cart', {
					action: 'remove',
					cartType: 'wishlist',
					items: {
						name: item.DisplayName,
						modelCode: item.ModelCode,
						fgCode: item.Code,
						price: item.LinkedPlacedPriceFormatted ?? item.PlacedPriceFormatted,
						priceDecimal: item.LinkedPlacedPrice ?? item.PlacedPrice,
						brand: item.Brand,
						quantityChange: item.Quantity
					}
				});
			},
			onClickAccount: function () {
				const $form = $(`.js-account`);
				_.chain(this.$data).get('data.account').each((val, key) => {
					console.log(key, val);
					console.log($form.find(`[name=${key}]`).length);
					$form.find(`[name=${key}]`).val(val).trigger('blur');
				}).value();
			},
			onClickAddress: function (view, $event, address) {
				$event.preventDefault();
				console.log('onClickAddress');
				const $form = $(`.js-address`);
				if (!address) { $form.find(':input').val('').trigger('blur'); }
				_.each(address, (val, key) => {
					console.log(val , key)
					$form.find(`[name=${key}]`).val(val).trigger('blur');
				});
				this.$data.state.addressView = view;
				const countryCode = $(`[name=CountryCode]`).val();
				_.set(this.$data.data, `currentCountryCode`, countryCode);
				vApp.$on('address:getRegions:then', (res) => {
					vApp.$off('address:getRegions:then');
					console.log('res', res);
					_.set(this.$data.data, `regions`, res || []);
					if (address) _.delay(() => $(`${'#regioncode'}`).val(address.RegionCode), 0);
				});
				vApp.$emit('address:getRegions', { countryCode: countryCode || address.CountryCode });
			},
			onClickCancel: function (item, $event) {
				$event.preventDefault();
				console.log('onClickCancel');
				vApp.$emit('order:cancelOrder', { purchaseOrderNumber: item.PurchaseorderNumber });
			},
			onChangeCountryCode: function ($event) {
				console.log('onChangeCountryCode');
				const countryCode = $event.target.value;
				_.set(this.$data.data, `currentCountryCode`, countryCode);
				vApp.$on('address:getRegions:then', (res) => {
					vApp.$off('address:getRegions:then');
					console.log('res', res);
					_.set(this.$data.data, `regions`, res || []);
				});
				vApp.$emit('address:getRegions', { countryCode });
			},
			onChangeFile: function (name, $event) {
				const me = this;
				console.log('name', name);
				console.log('$event', $event);
				const files = _.get($event.target, 'files', []);
				if (name === 'ProofOfPurchase') {
					const file = _.find(files, i => i.size > 10000000);
					_.set(me.$data, 'state.fields.errors.ProofOfPurchase', undefined);
					if (file) {
						vApp.$emit('snackbar:show', {
							msg: _.get(snackbarCfg, 'filesSizeInvalid'),
							err: true
						});
						return;
					}
					const hasNameIssue = _.find(files, i => !/^[a-zA-Z0-9-_]+$/.test(_.chain(i).get('name').split('.').first().value()));
					if (hasNameIssue) {
						vApp.$emit('snackbar:show', {
							msg: _.get(snackbarCfg, 'filesNameInvalid'),
							err: true
						});
						return;
					}
				}
				if (name === 'ProductImages') {
					_.set(me.$data, 'state.fields.errors.ProofOfPurchase', undefined);
					if (files.length > 3) {
						vApp.$emit('snackbar:show', {
							msg: _.get(snackbarCfg, 'formOnInvalid'),
							err: true
						});
						return;
					}
					const file = _.find(files, i => i.size > 10000000);
					if (file) {
						vApp.$emit('snackbar:show', {
							msg: _.get(snackbarCfg, 'filesSizeInvalid'),
							err: true
						});
						return;
					}
					const hasNameIssue = _.find(files, i => !/^[a-zA-Z0-9-_]+$/.test(_.chain(i).get('name').split('.').first().value()));
					if (hasNameIssue) {
						vApp.$emit('snackbar:show', {
							msg: _.get(snackbarCfg, 'filesNameInvalid'),
							err: true
						});
						return;
					}
					const names = _.chain(files).map(i => i.name).uniq().value();
					if (files.length !== names.length) {
						vApp.$emit('snackbar:show', {
							msg: _.get(snackbarCfg, 'filesUniqInvalid'),
							err: true
						});
						return;
					}
				}
				console.log('FileReader');
				if (!FileReader) { return; }
				const imgs = [];
				_.each(files, i => {
					const fr = new FileReader();
					fr.onload = function () {
						console.log('fr', fr);
						imgs.push(fr.result);
						const _name = _.camelCase(name);
						console.log('_name', _name);
						console.log('imgs', imgs);																																							
						_.set(me.$data.data, _name, imgs);	
					}
					fr.readAsDataURL(i);
				});
				console.log('imgs', imgs);
			}
		},
		mounted: function () {

			vApp.$on('order:getOrders:then', (res) => {
				const orders = _.reduce(res.Orders, (m, i) => {
					return m.concat({
						...i,
						PurchaseDate: (new Date(i.PurchaseDate)).toLocaleDateString(),
						DisplayStatus: _.startCase(i.DisplayStatus)
					});
				}, []);
				let pages = [];
				for (var i = 1; i <= res.PageTotal; i++) {
					pages.push(i);
				}
				_.set(this.$data, 'data.orders', { ...res, pages, Orders: orders });
			});
			vApp.$on('order:getOrders:ServiceOrder:then', (res) => {
				const orders = _.reduce(res.Orders, (m, i) => {
					return m.concat({
						...i,
						PurchaseDate: (new Date(i.PurchaseDate)).toLocaleDateString(),
						DisplayStatus: _.startCase(i.DisplayStatus)
					});
				}, []);
				let pages = [];
				for (var i = 1; i <= res.PageTotal; i++) {
					pages.push(i);
				}
				_.set(this.$data, 'data.serviceOrders', { ...res, pages, Orders: orders });
			});
			vApp.$on('order:cancelOrder:then', () => {
				vApp.$emit('order:getOrders');
			});
			vApp.$on('myaccount:getAddresses:then', (res) => {
				_.set(this.$data, 'data.addresses', [ ...res ]);
				_.set(this.$data, 'data.listAddresses', _.take([ ...res], 2));
			});
			vApp.$on('myaccount:getCustomerInfo:then', (res) => {
				_.set(this.$data, 'data.account', { ...res });
			});
			vApp.$on('cart:getCart:Wishlist:then', (res) => {
				_.set(this.$data, 'data.cart', { ...res });
			});
			vApp.$on('cart:transferCart:then', () => {
				vApp.$emit('cart:getCart', { cartName: 'Wishlist' });
				vApp.$emit('cart:getCart');
				vApp.$emit('cart:getCartItemCount', { cartName: 'Wishlist' });
				vApp.$emit('cart:getCartItemCount');
			});
			vApp.$on('cart:transferLine:then', () => {
				vApp.$emit('cart:getCart', { cartName: 'Wishlist' });
				vApp.$emit('cart:getCart');
				vApp.$emit('cart:getCartItemCount', { cartName: 'Wishlist' });
				vApp.$emit('cart:getCartItemCount');
			});
			vApp.$on('cart:putCart:then', () => {
				vApp.$emit('cart:getCart', { cartName: 'Wishlist' })
				vApp.$emit('cart:getCartItemCount', { cartName: 'Wishlist' });
			});
			vApp.$emit('order:getOrders');
			vApp.$emit('myaccount:getAddresses');
			vApp.$emit('myaccount:getCustomerInfo')
			vApp.$emit('cart:getCart', { cartName: 'Wishlist' });

			_.includes(window.location.href, '#wishlist') && this.onClickView('WISHLIST', { preventDefault: () => { } });
			_.includes(window.location.href, '#request-add') && this.onClickView('REQUEST_ADD', { preventDefault: () => { } });
			
		},
		beforeDestroyed: function () {
			vApp.$off('orders:getOrders:then');
			vApp.$off('orders:getOrders:ServiceOrder:then');
			vApp.$off('orders:cancelOrder:then');
			vApp.$off('myaccount:getAddresses');
			vApp.off('cart:transferCart:then');
			vApp.off('cart:transferLine:then');
			vApp.$off('cart:getCart');
			vApp.$off('cart:getCartItemCount');
			vApp.$off('cart:putCart');
		}
	});
	global.vAccount = vAccount;
});