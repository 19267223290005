/*
 * See youtube-init.js for initialization of YouTube player
 * (populates 'videoGalleryPlayer' variable with related players)
 */
const videoResponsive = [{
	breakpoint: 1024,
	settings: {
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: true,
	}
},
{
	breakpoint: 640,
	settings: {
		slidesToShow: 2.5,
		slidesToScroll: 1,
		arrows: false
	}
},
{
	breakpoint: 480,
	settings: {
		slidesToShow: 2.5,
		slidesToScroll: 1,
		centerMode: true,
		arrows: false
	}
},
{
	breakpoint: 0,
	settings: {
		slidesToShow: 2.5,
		slidesToScroll: 1,
		arrows: false
	}
}];
const videoArgs = {
	infinite: false,
	mobileFirst: true,
	arrows: true,
	responsive: videoResponsive
};
let canChangeVideo = true;

$(function() {
	const $videoThumbs = $('#video-gallery-navigation-thumbnails li');
	videoArgs.nextArrow = $videoThumbs.parent().parent().find('.video-gallery-navigation-arrow--next');
	videoArgs.prevArrow = $videoThumbs.parent().parent().find('.video-gallery-navigation-arrow--prev');

	$videoThumbs.parent().slick(videoArgs);
	$videoThumbs.first().addClass('active');

	$('#video-gallery-navigation-thumbnails')
			.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
				canChangeVideo = false;
			})
			.on('afterChange', function(slick, currentSlide) {
				canChangeVideo = true;
			});

	const $player = $('#video-gallery-player-frame');
	$videoThumbs.on('click', function(e) {
		const $this = $(this);
		if (canChangeVideo) {
			if (!$this.hasClass('active')) {
				$videoThumbs.removeClass('active');
				$this.addClass('active');
				if (window.videoGalleryPlayer != null) {
					const videoId = $this.attr('rel');
					window.videoGalleryPlayer.loadVideoById(videoId);
				}
			}
		}
	});
});
