/*
 * See youtube-init.js for initialization of YouTube player
 * (populates 'players' variable with related players)
 */

const activeClass = 'product-detail-gallery-navigation-thumbnails-active';
const altArrowClass = 'product-detail-gallery-navigation-arrow--alt';

const responsiveArgs = [{
	breakpoint: 1024,
	settings: {
		vertical: true,
		verticalSwiping: true,
		vertical: true,
		arrows: true,
		slidesToShow: 5,
		slidesToScroll: 1,
	}
},
{
	breakpoint: 768,
	settings: {
		swipeToSlide: true,
		slidesToShow: 7,
	}
},
{
	breakpoint: 640,
	settings: {
		slidesToShow: 6,
		swipeToSlide: true
	}
},
{
	breakpoint: 480,
	settings: {
		slidesToShow: 5,
		swipeToSlide: true
	}
},
{
	breakpoint: 0,
	settings: {
		slidesToShow: 4,
		swipeToSlide: true
	}
}
];

const sliderArgs = {
	mobileFirst: true,
	arrows: false,
	nextArrow: $('.product-detail-gallery-navigation-arrow--next'),
	prevArrow: $('.product-detail-gallery-navigation-arrow--prev'),
	infinite: false,
	responsive: responsiveArgs
};

function toggleArrowClass(add) {
	if (add == 'add') {
		$('.product-detail-gallery-navigation-arrow').addClass(altArrowClass);
	} else {
		$('.product-detail-gallery-navigation-arrow').removeClass(altArrowClass);
	}
}

$(function() {
	const $swatches = $('.product-banner-gallery').find('.product-swatches a');
	const $label = $('#color-label');

	$('.product-detail-gallery-navigation-thumbnails').slick(sliderArgs);

	$('.product-detail-gallery-navigation-thumbnails li').on('click', function() {
		const $this = $(this);
		$this.addClass(activeClass);
		$this.siblings().removeClass(activeClass);
		$('.product-detail-gallery-item--active').removeClass('product-detail-gallery-item--active');
		$('#' + $this.attr('rel')).addClass('product-detail-gallery-item--active');

		// Change swatch if this thumbnail has a color
		const color = $this.data('color');
		if (color.length) {
			const $swatch = $('.product-swatches a[data-color="' + color + '"]');
			$swatches.removeClass('selected');
			$swatch.addClass('selected');

			if ($label.length) $label.text($($swatch[0]).text());
		}
	});

	$('a.product-detail-gallery-item-play').on('click', function() {
		const ndx = $(this).attr('rel');

		setTimeout(function() {
			window.players[ndx].playVideo();
		}, 500);
	});

	if ($swatches.length) {
		$swatches.on('click', function(e) {
			e.preventDefault();
			const $this = $(this);
			if (!$this.hasClass('selected')) {
				$swatches.removeClass('selected');
				$this.addClass('selected');

				if ($label.length) {
					$label.text($this.text());
				}
			}

			// Change image
			const color = $this.data('color');
			const $thumb = $('.product-detail-gallery-navigation-thumbnails li[data-color="' + color + '"]');
			$thumb.addClass(activeClass);
			$thumb.siblings().removeClass(activeClass);
			$('.product-detail-gallery-item--active').removeClass('product-detail-gallery-item--active');
			$('#' + $thumb.attr('rel')).addClass('product-detail-gallery-item--active');
		});
	}
});
