const OverviewFeaturePromo = function($element) {
	this.$element = $element;
	this.$document = $(document);
	this.$window = $(window);

	this.classContentDiv = 'overview-feature-promo-container';
	this.classContentDivShow = this.classContentDiv + '--show';

	this.$video = this.$element.find('video');
	this.$content = this.$element.find('.' + this.classContentDiv);

	this.videoStarted = false;

	this.init();
};

OverviewFeaturePromo.prototype = {
	init: function() {
		const self = this;

		self.$document.on('scroll', function() {
			self.scrollHandler(self);
		});

		self.$window.resize(function() {
			self.setHeight();
		});

		if (self.elementBottomInView()) {
			self.playVideo();
			self.setHeight();
		}
	},
	scrollHandler: function(self) {
		self.setHeight();
		if (!self.videoStarted) {
			if (self.elementBottomInView()) {
				self.playVideo();
			}
		}
	},
	playVideo: function() {
		const self = this;

		self.videoStarted = true;
		if (self.$video.length) {
			self.$video.get(0).play();
		}

		self.$content.addClass(this.classContentDivShow);
		setTimeout(function() {
			self.$content.find('> *').addClass('show');
		}, 750);
	},
	setHeight: function() {
		const self = this;
		if (self.$video.length) {
			self.$element.height(self.$video.height());
		}
	},
	elementBottomInView: function() {
		const self = this;

		const elementBottom = self.$element.offset().top + self.$element.outerHeight();
		const currentViewportBottom = self.$window.scrollTop() + self.$window.height();

		return currentViewportBottom >= elementBottom;
	},
};

$(function() {
	const $promos = $('.overview-feature-promo');
	if ($promos.length != 0) {
		$promos.each(function() {
			new OverviewFeaturePromo($(this));
		});
	}
});
