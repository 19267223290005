const JobListing = function($element) {
	this.namespace = 'JobListing';
	this.$container = $element;
	this.$body = $('body');
	this.$header = $('.header');
	this.$btnRefine = $('#btn-refine');
	this.$filterContainer = $('#filter-container');
	this.$clearAllBtn = $('#btn-clear-all');
	this.$filterCheckboxes = $element.find('.listing-filters input[type="checkbox"]');
	this.$filterToggles = $element.find('a.listing-filters-toggle');
	this.$mobileCloseBtns = $element.find('#btn-filters-close, #btn-done-top, #btn-done-bottom');
	this.$items = $element.find('.job-listing-item');
	this.$emptyMsg = $element.find('#job-listing-empty');

	this.cssToggleOpen = 'listing-filters-section-content--open';
	this.cssMenuOpen = 'menu-open';
	this.cssFilterMenuOpen = 'listing-filters--open';

	this.init();
};
JobListing.prototype = {
	init: function() {
		const self = this;

		self.$filterCheckboxes.on('click.' + self.namespace, function(e) {
			self.updateFilters();
		});

		self.$filterToggles.on('click.' + self.namespace, function(e) {
			e.preventDefault();
			const $this = $(this);
			const $parent = $this.parent();

			if ($parent.hasClass(self.cssToggleOpen)) {
				$parent.removeClass(self.cssToggleOpen);
				$this.html($this.data('more'));
			} else {
				$parent.addClass(self.cssToggleOpen);
				$this.html($this.data('less'));
			}
		});

		self.$btnRefine.on('click.' + self.namespace, function(e) {
			e.preventDefault();
			self.$filterContainer.addClass(self.cssFilterMenuOpen);
			self.$filterContainer.css('top', $(window).scrollTop() + 'px');
			self.$body.addClass(self.cssMenuOpen);
			self.$header.css('z-index', '1');
		});

		self.$mobileCloseBtns.on('click.' + self.namespace, function(e) {
			e.preventDefault();
			self.$filterContainer.removeClass(self.cssFilterMenuOpen);
			self.$body.removeClass(self.cssMenuOpen);
			self.$header.css('z-index', '10');
		});

		self.$clearAllBtn.on('click.' + self.namespace, function(e) {
			e.preventDefault();

			self.$filterCheckboxes.prop('checked', false);
			$('li.listing-breadcrumbs--tag').remove();

			self.updateFilters();
		});
	},
	updateFilters: function() {
		const self = this;

		const deptFilters = [];
		const locFilters = [];
		self.$filterCheckboxes.filter(':checked').each(function() {
			const $this = $(this);
			const valueArray = $this.attr('value').split('|');
			const value = valueArray[0];

			if ($this.attr('name') === 'dept') {
				deptFilters.push(value);
			} else if ($this.attr('name') === 'loc') {
				locFilters.push(value);
			}
		});

		self.$emptyMsg.hide();
		self.$items.hide();
		if (deptFilters.length === 0 && locFilters.length === 0) {
			self.$items.show();
		}
		else if (deptFilters.length > 0 && locFilters.length > 0) {
			self.$items.each(function () {
				const $this = $(this);
				const itemLocs = $this.attr('data-loc').split('|');
				const dept = deptFilters.includes($this.attr('data-dept'));
				const location = itemLocs.some(x => locFilters.indexOf(x) >= 0);
				if (dept && location) {
					$this.show();
				}
            })
		}
		else {
			$.each(deptFilters, function(index, rawValue) {
				self.$items.filter('div[data-dept*="' + rawValue + '"]').show();
			});

			$.each(locFilters, function(index, rawValue) {
				self.$items.filter('div[data-loc*="' + rawValue + '"]').show();
			});
		}

		if (self.$items.filter(':visible').length === 0) {
			self.$emptyMsg.show();
		}
	},
};

$(function() {
	const $element = $('#job-listing');

	if ($element.length != 0) {
		new JobListing($element);
	}
});
