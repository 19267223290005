const tag = document.createElement('script');
tag.src = 'https://www.youtube.com/iframe_api';
const firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

window.players = [];
window.videoGalleryPlayer = null;
console.log('YouTubeInit');
window.onYouTubeIframeAPIReady = function onYouTubeIframeAPIReady() {
	console.log('onYouTubeIframeAPIReady');
	const $productBannerVideos = $('.product-banner-gallery-video');

	if ($productBannerVideos.length) {
		$('.product-banner-gallery-video').each(function () {
			console.log('onYouTubeIframeAPIReady:productBannerGalleryVideo');
			const ndx = $(this).attr('rel');
			const id = $(this).attr('id');

			window.players[ndx] = new YT.Player(id, {
				events: {}
			});
		});
	}

	const videoGallery = document.getElementById('video-gallery-player-frame');
	if (videoGallery) {
		console.log('onYouTubeIframeAPIReady:videoGallery');
		window.videoGalleryPlayer = new YT.Player('video-gallery-player-frame', {
			videoId: videoGallery.getAttribute('videoId'),
			playerVars: {
				enablejsapi: 1,
				autoplay: 0,
				origin: 'https://sram.com',
				title: videoGallery.getAttribute('videoId'), 
			},
			events: {}
		});
	}
}
