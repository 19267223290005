import Vue from "vue";

var l10nMixin = {
  methods: {
    l10n: function (text) {
      if (this["l10nStrings"] && this["l10nStrings"][text]) {
        return this["l10nStrings"][text];
      } else {
        console.warn("Missing translation", {
          text,
          component: this.$options.name,
        });
        return text;
      }
    },
  },
};

Vue.component("service-prodsearch-results-list-component", {
  props: {
    results: { type: () => [] },
  },
  methods: {
    fixupImageUrl(url) {
      const fixedUrl = new URL(url, window.location.origin);
      const fixedUrlParams = new URLSearchParams(fixedUrl.search);

      fixedUrlParams.set("width", 50);
      fixedUrlParams.set("height", 50);

      fixedUrl.search = fixedUrlParams;

      return fixedUrl;
    },
  },
  template: `
        <div class="service-prodsearch-results-list">
            <a v-for="result in results" :href="result.url" :title="result.name">
                <div>
                    <img :src="fixupImageUrl(result.image)" :alt="result.name" width="50" height="50" onerror="this.onerror=null;this.src='/common/img/image-not-found.png';" >
                </div>
                <p>{{ result.name }}<br><span class="sku">{{ result.code }}</span></p>
            </a>
        </div>
        `,
});

Vue.component("service-prodsearch-nav-dropdown-component", {
  mixins: [l10nMixin],
  props: {
    l10nStrings: Object,
    name: { type: String },
    options: { type: () => [] },
    selected: { type: String },
    filterText: { type: String },
  },
  computed: {
    showSearch() {
      return this.options && this.options.length > 12;
    },
    filteredFilters() {
      return this.filterText
        ? this.options.filter(
            (f) => f.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1
          )
        : this.options;
    },
  },
  watch: {
    selected: function (newValue, oldValue) {
      this.filterText = "";

      this.$emit("item-selected", {
        value: newValue,
      });
    },
  },
  data: function () {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    closeToggle() {
      this.isOpen = false;
    },
    select(value) {
      this.selected = value;
      this.closeToggle();
    },
    clearSelection() {
      this.selected = "";
      this.filterText = "";
    },
  },
  template: `
        <li v-click-outside="closeToggle" class="service-prodsearch-nav-item--series service-prodsearch-filter" :class="{ 'service-prodsearch-nav-filtergroup--open': isOpen }">
            <a @click.prevent="toggle" data-value="" class="service-prodsearch-nav-filtergroup" :title="name + ' ' + l10n('Link')">{{ name }}: <span class="selection">{{ selected ? selected : l10n("Select") }}</span></a>   
            <div id="filter-series" class="service-prodsearch-nav-dropdown-wrapper">
                <div class="service-prodsearch-nav-dropdown">
                    <div v-if="showSearch" class="service-prodsearch-nav-dropdown-search">
                        <input type="text" name="t" class="service-prodsearch-nav-dropdown-searchinput" v-model:value="filterText" :placeholder="l10n('Search') + ' ' + name">
                    </div>
                    <div class="service-prodsearch-nav-dropdown-content" v-if="filteredFilters">
                        <a v-for="filter in filteredFilters"  class="service-prodsearch-nav-filter" :title="filter" @click.prevent="select(filter)">{{filter}}</a>
                        <a @click.prevent="clearSelection" class="service-prodsearch-nav-dropdown-clear" title="l10n('Clear')">{{ l10n('Clear') }}</a>
                        <p v-show="!filteredFilters" class="service-prodsearch-nav-dropdown-noresults">{{ l10n('There are no items matching your search term.') }}</p>
                    </div>
                </div>
            </div>
        </li>
	`,
});

Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.clickOutsideHandler = function (event) {
      if (event && !(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideHandler);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideHandler);
  },
});
if (document.getElementById("service-prodsearch-block"))
  $(function () {
    const el = "#service-prodsearch-block";
    const $el = $(el);

    if (!$el) {
      return;
    }

    new Vue({
      el: el,
      name: el,
      mixins: [l10nMixin],
      data: function () {
        return {
          page: 1,
          loading: false,
          l10nStrings: () => [],

          brandDropdown: {
            options: () => [],
            selected: "",
            filterText: "",
          },
          categoryDropdown: {
            options: () => [],
            selected: "",
            filterText: "",
          },
          seriesDropdown: {
            options: () => [],
            selected: "",
            filterText: "",
          },
          results: {
            page: 1,
            products: () => [],
            resultsPerPage: 0,
            totalPages: 0,
            totalResults: 0,
          },
        };
      },
      methods: {
        fetchData() {
          const urlParams = new URLSearchParams();

          if (this.page) urlParams.append("page", this.page);

          if (this.brandDropdown.selected)
            urlParams.append("brand", this.brandDropdown.selected);

          if (this.categoryDropdown.selected)
            urlParams.append("category", this.categoryDropdown.selected);

          if (this.seriesDropdown.selected)
            urlParams.append("series", this.seriesDropdown.selected);

          this.loading = true;

          fetch("/api/service/browse?" + urlParams)
            .then((response) => response.json())
            .then((data) => {
              if (data.results) {
                this.page = data.results.page;
                this.results = data.results;
              }

              this.brandDropdown.options = data.facets.brands;
              this.categoryDropdown.options = data.facets.categories;
              this.seriesDropdown.options = data.facets.series;

              this.loading = false;
            });
        },
        brandSelected(brand) {
          this.brandDropdown.selected = brand.value;
          this.fetchData();
        },
        categorySelected(category) {
          this.categoryDropdown.selected = category.value;
          this.fetchData();
        },
        seriesSelected(series) {
          this.seriesDropdown.selected = series.value;
          this.fetchData();
        },
        nextPage() {
          if (this.page < this.results.totalPages) {
            this.page = this.page + 1;
            this.fetchData();
          }
        },
        previousPage() {
          if (this.page > 1) {
            this.page = this.page - 1;
            this.fetchData();
          }
        },
        clearAll() {
          this.brandDropdown.selected = "";
          this.brandDropdown.filterText = "";

          this.categoryDropdown.selected = "";
          this.categoryDropdown.filterText = "";

          this.seriesDropdown.selected = "";
          this.seriesDropdown.filterText = "";

          this.results = "";
        },
        addLeadingZero(num) {
          return num < 10 ? "0" + num : "" + num;
        },
      },
      mounted: function () {
        this.l10nStrings = JSON.parse(this.$el.dataset.l10nStrings);

        this.fetchData();
      },
    });
  });
