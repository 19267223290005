const SerialLookup = function($element) {
	this.namespace = 'SerialLookup';
	this.$form = $element;
	this.$submitBtn = $('#btn-spec-table-search');
	this.$input = $('#input-spec-table-search');
	this.$specTable = $('#spec-table');
	this.$errorBox = $('#spec-table-search-error');
	this.$fieldWrapper = this.$input.parents('.field');

	this.rootFormAction = this.$form.attr('action');
	this.modelId = this.$form.data('modelid');

	this.init();
};

SerialLookup.prototype = {
	init: function() {
		const self = this;
		const params = new URLSearchParams(window.location.search);
		const searchTerm = params.get('t');

		self.$form.on('submit', function(e) {
			console.log('form submit');
			e.preventDefault();
			const val = self.$input.val();
			if (val.length) {
				self.doLookup(val);
			} else {
				self.renderError('Serial number is required.');
			}
		});

		self.$submitBtn.on('click.' + self.namespace, function(e) {
			e.preventDefault();

			const val = self.$input.val();
			if (val.length) {
				self.doLookup(val);
			} else {
				self.renderError('Serial number is required.');
			}
		});

		if (searchTerm && searchTerm.length) {
			self.$input.val(searchTerm);
			self.$form.trigger('submit');
		}
	},
	doLookup: function(value) {
		const self = this;
		const search = window.location.search;

		self.clearError();

		$.ajax({
			contentType: 'application/json; charset=utf-8',
			url: self.rootFormAction.replace(search, '') + '/SerialLookup' + search,
			method: 'POST',
			dataType: 'json',
			traditional: true,
			data: JSON.stringify({
				serial: value,
				modelId: self.modelId
			}),
			success: function(data) {
				if (data.Success) {
					self.$specTable.html(data.SpecHtml);
				} else {
					self.renderError(data.ErrorMessage);
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				console.log(xhr.status);
				console.log(thrownError);
				self.renderError('This serial you entered was not found.');
			}
		});
	},
	renderError: function(msg) {
		const self = this;

		if (!self.$fieldWrapper.hasClass('error')) {
			self.$fieldWrapper.addClass('error');
		}

		self.$errorBox.html(msg);
	},
	clearError: function() {
		const self = this;

		self.$fieldWrapper.removeClass('error');
		self.$errorBox.html('');
	}
};

$(function() {
	const $element = $('#spec-table-search');
	if ($element.length != 0) {
		new SerialLookup($element);
	}
});
