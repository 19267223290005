class Tabs {
  constructor() {
    this.assignTopicId();
    this.initializeFirstTab();
    this.bindAll();
  }

  // Assign dynamic id to each Topic instance
  assignTopicId() {
    const topicHeadlines = document.querySelectorAll(
      ".segmented-topic-content"
    );
    const topicLinks = document.querySelectorAll(".segmented-topic-nav-btn");

    // Use forEach instead of traditional for loops for better readability
    topicHeadlines.forEach((headline, index) => {
      headline.setAttribute("id", `topics-${index}`);
    });

    topicLinks.forEach((link, index) => {
      link.setAttribute("data-topic", `topics-${index}`);
    });
  }

  // Moved logic for activating first tab into its method to enhance readability
  initializeFirstTab() {
    const firstTab = document.querySelector(".segmented-topic-nav-btn");
    const firstTabContent = document.querySelector(".segmented-topic-content");

    // Assign 'active' to the first instance of a Topic
    if (firstTab && firstTabContent) {
      firstTab.classList.add("active");
      firstTabContent.classList.add("active");
    }
  }

  // Clearing active classes
  clearActiveStates() {
    const menuElements = document.querySelectorAll("[data-topic]");

    menuElements.forEach((menuElement) => {
      menuElement.classList.remove("active");
      const id = menuElement.getAttribute("data-topic");
      document.getElementById(id).classList.remove("active");
    });
  }

  // Changing active state based on the target element
  changeActiveState(e) {
    this.clearActiveStates();
    e.target.classList.add("active");
    const id = e.currentTarget.getAttribute("data-topic");
    document.getElementById(id).classList.add("active");
  }

  // Binding event listeners
  bindAll() {
    const menuElements = document.querySelectorAll("[data-topic]");

    menuElements.forEach((menuElement) => {
      menuElement.addEventListener(
        "click",
        this.changeActiveState.bind(this),
        false
      );
    });
  }
}

// Instantiating the Tabs class
const connectTabs = new Tabs();
