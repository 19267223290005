$(function() {
	// Get Total SLides
	const totalChildren = $('.hero-carousel-slides').children().length;
	$('.hero-carousel-arrows-pager-total').text(totalChildren);

	$('.hero-carousel .hero-carousel-slides').slick({
		arrows: true,
		appendArrows: $('.hero-carousel-arrows'),
		dots: true,
		appendDots: $('.hero-carousel'),
		dotsClass: 'hero-carousel-dots',
		infinite: true,
		fade: true,
		cssEase: 'linear',
		autoplay: true,
		autoplaySpeed: 7000
	});

	// Update Pager on slide change
	$('.hero-carousel .hero-carousel-slides').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
		$('.hero-carousel-arrows-pager-current').text(nextSlide + 1);
	});
});
