import * as _ from 'lodash';
import Vue from 'vue';


$(function () {
	const el = '.v-cart-header';
	const $el = $(el);
	if (!$el.length) {
		return;
	}
	const vCartIcon = new Vue({
		el: el,
		name: el,
		data: {
			data: {
				cartItemCount: {}
			},
			state: {
				isEmpty: true
			}
		},
		mounted: function () {
			vApp.$on('cart:getCartItemCount:then', (res) => {
				console.log('cart:getCartItemCount:then', res);
				_.set(this.$data, 'data.cartItemCount', { ...res });
				_.set(this.$data, 'state.isEmpty', !res.TotalItems);
			});

			vApp.$emit('cart:getCartItemCount');
		},
		beforeDestroyed: function () {
			vApp.$off('cart:getCartItemCount:then');
		}
	});
	global.vCartIcon = vCartIcon;
});

$(function () {
	const el = '.v-cart-header-mobile';
	const $el = $(el);
	if (!$el.length) {
		return;
	}
	const vCartIcon = new Vue({
		el: el,
		name: el,
		data: {
			data: {
				cartItemCount: {}
			},
			state: {
				isEmpty: true
			}
		},
		mounted: function () {
			vApp.$on('cart:getCartItemCount:then', (res) => {
				console.log('cart:getCartItemCount:then', res);
				_.set(this.$data, 'data.cartItemCount', { ...res });
				_.set(this.$data, 'state.isEmpty', !res.TotalItems);
			});

			vApp.$emit('cart:getCartItemCount');
		},
		beforeDestroyed: function () {
			vApp.$off('cart:getCartItemCount:then');
		}
	});
	global.vCartIcon = vCartIcon;
});


$(function () {
	const el = '.v-cart-wishlist-header';
	const $el = $(el);
	if (!$el.length) {
		return;
	}
	const vCartIcon = new Vue({
		el: el,
		name: el,
		data: {
			data: {
				cartItemCount: {}
			},
			state: {
				isEmpty: true
			}
		},
		mounted: function () {
			vApp.$on('cart:getCartItemCount:Wishlist:then', (res) => {
				console.log('cart:getCartItemCount:Wishlist:then', res);
				_.set(this.$data, 'data.cartItemCount', { ...res });
				_.set(this.$data, 'state.isEmpty', !res.TotalItems);
			});

			vApp.$emit('cart:getCartItemCount', { cartName: 'Wishlist' });
		},
		beforeDestroyed: function () {
			vApp.$off('cart:getCartItemCount:Wishlist:then');
		}
	});
	global.vCartIcon = vCartIcon;
});
$(function () {
	const el = '.v-cart-build-cfg';
	const $el = $(el);
	if (!$el.length) {
		return;
	}
	const buildCfg = _.get(window, 'buildCfg');
	if (!buildCfg) {
		return;
	}
	const vCartBuildCfg = new Vue({
		el: el,
		name: el,
		data: {
			data: {
				qty: 1,
				cartName: 'Default',
				buildCfg: {
					...buildCfg
				}
			},
			state: {
				prevBuildCfg: false,
				showBuildCfg: false,
				enableCart: false,
				hasInventory: false,
				inventoryLeadTime: ''
			}
		},
		computed: {
			formattedInventoryLeadTime: function () {
				return new Date(this.$data.state.inventoryLeadTime).toLocaleDateString();
			}
		},
		methods: {
			onClickClose: function ($event) {
				this.$data.state.showBuildCfg = false;
			},
			onClickCfg: function (cartName, $event) {
				$event.preventDefault();
				this.data.cartName = cartName;
			},
			onClickOpt: function (dd, opt, $event) {
				$event.preventDefault();

				const displayName = opt ? opt.displayName : $event.target.value;

				// NOTE: Update selection
				_.chain(dd)
					.get('Options')
					.each(i => {
						i.isSelected = i.displayName === displayName ? !i.isSelected : false;
					})
					.value();

				const buildCfg = _.get(this.$data, 'data.buildCfg');
				const filters = _.chain(this.$data)
									.get('data.buildCfg.DropDowns')
									.cloneDeep()
									// NOTE: Filter for selection
									.filter(i =>
										i.DropDownIndex <= dd.DropDownIndex &&
										(i.IsAlreadyOwnSelected || !!_.find(i.Options, _i => _i.isSelected))
									)
									.map(i => {
										i.Options = _.chain(i.Options)
														.filter(_i => _i.isSelected)
														.map(_i => _i.displayName)
														.value();
										i.IsSelected = true;
										i.IsEnabled = true;
										return i;
									})
									.value();

				vApp.$emit('buywidget:post', { buildCfg, filters });
			},
			onClickOwn: function (dd, $event) {
				$event.preventDefault();

				dd.IsAlreadyOwnSelected = !dd.IsAlreadyOwnSelected;

				const buildCfg = _.get(this.$data, 'data.buildCfg');
				const filters = _.chain(this.$data)
					.get('data.buildCfg.DropDowns')
					.cloneDeep()
					// NOTE: Filter for selection
					.filter(i =>
						i.DropDownIndex <= dd.DropDownIndex &&
						(i.IsAlreadyOwnSelected || !!_.find(i.Options, _i => _i.isSelected))
					)
					.map(i => {
						i.Options = _.chain(i.Options)
							.filter(_i => _i.isSelected)
							.map(_i => _i.displayName)
							.value();
						i.IsSelected = true;
						i.IsEnabled = true;
						return i;
					})
					.value();
				vApp.$emit('buywidget:post', { buildCfg, filters });
			},
			onChangeCartQuantity: function (action, $event) {
				switch (action) {
					case 'MINUS':
						this.$data.data.qty = this.$data.data.qty > 1 ? this.$data.data.qty - 1 : 1;
						break;
					case 'PLUS':
						this.$data.data.qty = this.$data.data.qty + 1;
						break;
					case 'INPUT':
						this.$data.data.qty = $event.target.value;
						break;
				}
			},
			onClickAddToCart: function ($event) {
				$event.preventDefault();
				var specifications = "";
				if (this.$data.data.buildCfg.DropDowns.length) {
					specifications = _.chain(this.$data)
						.get('data.buildCfg.DropDowns')
						.cloneDeep()
						// NOTE: Filter for selection
						.filter(i => !!_.find(i.Options, _i => _i.isSelected))
						.map(i => {
							return {
								DisplayName: i.DisplayName,
								Value: _.chain(i.Options)
									.filter(_i => _i.isSelected)
									.map(_i => _i.displayName)
									.first()
									.value()
							};
						})
						.value();
				} else {
					specifications = _.chain(this.$data)
						.get('data.buildCfg.FGs[0].FGSpecs')
						.cloneDeep()
						.map(i => {
							return {
								DisplayName: i.DisplayName,
								Value: i.Value
							};
						})
						.value();
                }

				vApp.$emit('cart:postCart', {
					skuCode: this.$data.data.buildCfg.FGCode,
					skuCodes: this.$data.data.buildCfg.FGCodes,
					modelCode: this.$data.data.buildCfg.ModelCode,
					qty: this.$data.data.qty,
					cartName: this.$data.data.cartName,
					specifications
				});

				vApp.$emit('cart:getCartItemCount');

				vApp.$emit('analytics:cart', {
					action: 'add',
					cartType: this.$data.data.cartName,
					items: {
						currencyCode: this.$data.data.buildCfg.Currency,
						fgCode: this.$data.data.buildCfg.FGCode,
						modelCode: this.$data.data.buildCfg.ModelCode,
						price: this.$data.data.buildCfg.Price,
						priceDecimal: this.$data.data.buildCfg.PriceDecimal,
						currency: this.$data.data.buildCfg.Currency,
						brand: this.$data.data.buildCfg.Brand,
						quantityChange: this.$data.data.qty
					}
				});
			},
			onClickAddToWishlist: function ($event) {
				$event.preventDefault();

				if (!this.$data.data.buildCfg.IsReadyToAddToCart) { return; }

				var specifications = "";
				if (this.$data.data.buildCfg.DropDowns.length) {
					specifications = _.chain(this.$data)
						.get('data.buildCfg.DropDowns')
						.cloneDeep()
						// NOTE: Filter for selection
						.filter(i => !!_.find(i.Options, _i => _i.isSelected))
						.map(i => {
							return {
								DisplayName: i.DisplayName,
								Value: _.chain(i.Options)
									.filter(_i => _i.isSelected)
									.map(_i => _i.displayName)
									.first()
									.value()
							};
						})
						.value();
				} else {
					specifications = _.chain(this.$data)
						.get('data.buildCfg.FGs[0].FGSpecs')
						.cloneDeep()
						.map(i => {
							return {
								DisplayName: i.DisplayName,
								Value: i.Value
							};
						})
						.value();
				}
				vApp.$emit('cart:postCart', {
					skuCode: this.$data.data.buildCfg.FGCode,
					skuCodes: this.$data.data.buildCfg.FGCodes,
					qty: this.$data.data.qty,
					cartName: 'Wishlist',
					specifications
				});
				vApp.$emit('cart:getCartItemCount', { cartName: 'Wishlist' });


				vApp.$emit('analytics:cart', {
					action: 'add',
					cartType: 'wishlist',
					items: {
						currencyCode: this.$data.data.buildCfg.Currency,
						fgCode: this.$data.data.buildCfg.FGCode,
						modelCode: this.$data.data.buildCfg.ModelCode,
						price: this.$data.data.buildCfg.Price,
						priceDecimal: this.$data.data.buildCfg.PriceDecimal,
						currency: this.$data.data.buildCfg.Currency,
						brand: this.$data.data.buildCfg.Brand,
						quantityChange: this.$data.data.qty
					}
				});
			}
		},
		mounted: function () {
			vApp.$on('cart:buildCfg', ({ cartName }) => {
				this.$data.data.cartName = cartName;
				this.$data.state.showBuildCfg = true;
				this.$data.state.prevBuildCfg = true;
				vApp.$emit('fbq:track', {
					event: 'CustomizeProduct'
				});
			});

			vApp.$on('buywidget:post:then', (res) => {
				const buildCfg = { ...this.$data.data.buildCfg, ...res };
				const dds = _.chain(buildCfg)
					.get('DropDowns')
					.filter({ IsSingleChoice: false })
					.map(i => {
						// NOTE: Get local options for merge
						i.Options = _.chain(this.$data)
							.get('data.buildCfg.DropDowns')
							.map(_i => i.IsSelected ? _i : i)
							.find(_i => _i.DropDownIndex === i.DropDownIndex)
							.get('Options', i.Options)
							.map(_i => {
								return {
									displayName: _i.displayName || _i,
									isSelected: i.IsSelected && _.includes(i.Options, (_i.displayName || _i))
								}
							})
							.value();
						return i;
					})
					.value();

				_.set(buildCfg, 'DropDowns', dds);

				_.set(this.$data, 'data.buildCfg', buildCfg);
				console.log('this.$data.data.buildCfg', this.$data.data.buildCfg);
				_.set(this.$data, 'state.enableCart', buildCfg.IsReadyToAddToCart && (buildCfg.IsInventoryAvailable || buildCfg.IsInventorySoon));
				_.set(this.$data, 'state.hasInventory', buildCfg.IsInventoryAvailable);
				_.set(this.$data, 'state.inventoryLeadTime', buildCfg.InventoryLeadTime);
			});

			vApp.$on('cart:postCart:then', () => {
				this.$data.state.showBuildCfg = false;
				vApp.$emit('buywidget:post', { buildCfg: this.$data.data.buildCfg, filters: [] });
				vApp.$emit('cart:getCart');
				vApp.$emit('cart:getCart', { cartName: 'Wishlist' });
				vApp.$emit('cart:getCartItemCount');
				vApp.$emit('cart:getCartItemCount', { cartName: 'Wishlist' });
			});

			const buildCfg = _.get(this.$data, 'data.buildCfg');
			vApp.$emit('buywidget:post', { buildCfg });
		},
		beforeDestroyed: function () {
			vApp.$off('cart:buildCfg');
			vApp.$off('buywidget:post:then');
			vApp.$off('cart:postCart:then');
		}
	});

	global.vCartBuildCfg = vCartBuildCfg;
});


$(function () {
	const el = '.v-cart-cfg';
	const $el = $(el);
	if (!$el.length) {
		return;
	}
	const buildCfg = _.get(window, 'buildCfg');
	console.log('buildCfg', buildCfg);
	if (!buildCfg) {
		return;
	}
	// $('.panel-cart-button').bigSlide({ side: 'right', menu: '#panel', menuWidth: '350px' });
	const vCartCfg = new Vue({
		el: el,
		name: el,
		data: {
			data: {
				qty: 1,
				buildCfg: {
					...buildCfg
				}
			},
			state: {
				hasCfg: false,
				hasInventory: false,
				inventoryLeadTime: ''
			}
		},
		computed: {
			formattedInventoryLeadTime: function () {
				return new Date(this.$data.state.inventoryLeadTime).toLocaleDateString();
			}
		},
		methods: {
			onClickCfg: function (cartName, $event) {
				$event.preventDefault();
				vApp.$emit('cart:buildCfg', { cartName });
			},
			onChangeCartQuantity: function (action, $event) {
				switch (action) {
					case 'MINUS':
						this.$data.data.qty = this.$data.data.qty > 1 ? this.$data.data.qty - 1 : 1;
						break;
					case 'PLUS':
						this.$data.data.qty = this.$data.data.qty + 1;
						break;
					case 'INPUT':
						this.$data.data.qty = $event.target.value;
						break;
				}
			},
			onClickAddToCart: function ($event) {
				$event.preventDefault();
				var specifications = "";
				if (this.$data.data.buildCfg.DropDowns.length) {
					specifications = _.chain(this.$data)
						.get('data.buildCfg.DropDowns')
						.cloneDeep()
						// NOTE: Filter for selection
						.filter(i => !!_.find(i.Options, _i => _i.isSelected))
						.map(i => {
							return {
								DisplayName: i.DisplayName,
								Value: _.chain(i.Options)
									.filter(_i => _i.isSelected)
									.map(_i => _i.displayName)
									.first()
									.value()
							};
						})
						.value();
				} else {
					specifications = _.chain(this.$data)
						.get('data.buildCfg.FGs[0].FGSpecs')
						.cloneDeep()
						.map(i => {
							return {
								DisplayName: i.DisplayName,
								Value: i.Value
							};
						})
						.value();
				}
				vApp.$emit('cart:postCart', {
					skuCode: this.$data.data.buildCfg.FGCode,
					skuCodes: this.$data.data.buildCfg.FGCodes,
					qty: this.$data.data.qty,
					cartName: 'Default',
					specifications
				});
				vApp.$emit('cart:getCartItemCount');

				vApp.$emit('analytics:cart', {
					action: 'add',
					cartType: 'cart',
					items: {
						currencyCode: this.$data.data.buildCfg.Currency,
						fgCode: this.$data.data.buildCfg.FGCode,
						modelCode: this.$data.data.buildCfg.ModelCode,
						price: this.$data.data.buildCfg.Price,
						priceDecimal: this.$data.data.buildCfg.PriceDecimal,
						currency: this.$data.data.buildCfg.Currency,
						brand: this.$data.data.buildCfg.Brand,
						quantityChange: this.$data.data.qty
					}
				});
			},
			onClickAddToWishlist: function ($event) {
				$event.preventDefault();

				var specifications = "";
				if (this.$data.data.buildCfg.DropDowns.length) {
					specifications = _.chain(this.$data)
						.get('data.buildCfg.DropDowns')
						.cloneDeep()
						// NOTE: Filter for selection
						.filter(i => !!_.find(i.Options, _i => _i.isSelected))
						.map(i => {
							return {
								DisplayName: i.DisplayName,
								Value: _.chain(i.Options)
									.filter(_i => _i.isSelected)
									.map(_i => _i.displayName)
									.first()
									.value()
							};
						})
						.value();
				} else {
					specifications = _.chain(this.$data)
						.get('data.buildCfg.FGs[0].FGSpecs')
						.cloneDeep()
						.map(i => {
							return {
								DisplayName: i.DisplayName,
								Value: i.Value
							};
						})
						.value();
				}
				vApp.$emit('cart:postCart', {
					skuCode: this.$data.data.buildCfg.FGCode,
					skuCodes: this.$data.data.buildCfg.FGCodes,
					qty: this.$data.data.qty,
					cartName: 'Wishlist',
					specifications
				});

				vApp.$emit('cart:getCartItemCount', { cartName: 'Wishlist' });

				vApp.$emit('analytics:cart', {
					action: 'add',
					cartType: 'wishlist',
					items: {
						currencyCode: this.$data.data.buildCfg.Currency,
						fgCode: this.$data.data.buildCfg.FGCode,
						modelCode: this.$data.data.buildCfg.ModelCode,
						price: this.$data.data.buildCfg.Price,
						priceDecimal: this.$data.data.buildCfg.PriceDecimal,
						currency: this.$data.data.buildCfg.Currency,
						brand: this.$data.data.buildCfg.Brand,
						quantityChange: this.$data.data.qty
					}
				});
			}
		},
		mounted: function () {
			vApp.$on('buywidget:post:then', (res) => {
				console.log('res', res);

				const buildCfg = { ...this.$data.data.buildCfg, ...res };
				const hasCfg = !_.chain(res).get('DropDowns').isEmpty().value() || res.IsReadyToAddToCart;
				
				!hasCfg && $('.product-title').addClass('d-none');
				hasCfg && $('.js-image-gallery').removeClass('col-12').addClass('col-7 col-md-12 col-sm-12 col-xs-12');
				hasCfg && $('.js-aside').removeClass('d-none').addClass('col-5 col-md-12 col-sm-12 col-xs-12');
				hasCfg && $('.js-b2c-link').removeClass('d-none');
				hasCfg && $('.js-power-reviews').removeClass('d-none');

				_.set(this.$data, 'data.buildCfg', buildCfg);
				console.log('this.$data.data.buildCfg', this.$data.data.buildCfg);
				_.set(this.$data, 'state.hasCfg', hasCfg);
				_.set(this.$data, 'state.hasInventory', buildCfg.IsInventoryAvailable);
				_.set(this.$data, 'state.inventoryLeadTime', buildCfg.InventoryLeadTime);
			});

			vApp.$on('buywidget:post:catch', (err) => {
				_.set(this.$data, 'state.hasCfg', false);
				console.log('buywidget:post:catch', err);
			});

			const buildCfg = _.get(this.$data, 'data.buildCfg');
			vApp.$emit('buywidget:post', { buildCfg });

		},
		beforeDestroyed: function () {
			vApp.$off('buywidget:post:then');
		}
	});
	global.vCartCfg = vCartCfg;
});

$(function () {
	const el = '.v-cart';
	const $el = $(el);
	if (!$el.length) {
		return;
	}
	const msg = $('[data-currency-switch-message]').data('currencySwitchMessage');
	if (msg) {
		vApp.$emit('snackbar:show', { msg, err: true });
	}
	const isCurrentlyUnderMaintenance = $('[data-is-currently-under-maintenance]').data('isCurrentlyUnderMaintenance');
	const vCart = new Vue({
		el: el,
		name: el,
		data: {
			data: {
				cart: {}
			},
			state: {
				isEmpty: true,
				isCurrentlyUnderMaintenance
			}
		},
		methods: {
			onChangeCartQuantity: function (action, item, $event) {
				$event.preventDefault();
				const skuCodes = _.chain(item).get('LinkedItems').reduce((m, i) => m.concat(i.Code), [item.Code]).value();
				let newQuantity, event, quantityChange;
				switch (action) {
					case 'MINUS':
						newQuantity = item.Quantity > 0 ? item.Quantity - 1 : 1;
						quantityChange = 1;
						event = 'remove';
						break;
					case 'PLUS':
						newQuantity = item.Quantity + 1;
						event = 'add';
						quantityChange = 1;
						break;
					case 'INPUT':
						newQuantity = $event.target.value;

						if (newQuantity > item.Quantity) {
							event = 'add';
							quantityChange = newQuantity - item.Quantity;
						} else {
							event = 'remove';
							quantityChange = item.Quantity - newQuantity;
						}
						break;
				}

				vApp.$emit('cart:putCart', { skuCode: item.Code, skuCodes, modelCode: item.ModelCode, linkId: item.LinkId, qty: newQuantity });

				vApp.$emit('analytics:cart', {
					action: event,
					cartType: 'cart',
					items: {
						name: item.DisplayName,
						fgCode: item.Code,
						modelCode: item.ModelCode,
						price: item.LinkedPlacedPriceFormatted ?? item.PlacedPriceFormatted,
						priceDecimal: item.LinkedPlacedPrice ?? item.PlacedPrice,
						currencyCode: item.Currency,
						brand: item.Brand,
						quantityChange: quantityChange
					}
				});
			},
			onClickCartRemove: function (item, $event) {
				$event.preventDefault();
				const skuCodes = _.chain(item).get('LinkedItems').reduce((m, i) => m.concat(i.Code), [item.Code]).value();
				vApp.$emit('cart:putCart', { skuCode: item.Code, skuCodes, modelCode: item.ModelCode, linkId: item.LinkId, qty: 0 });
				vApp.$emit('cart:getCartItemCount');

				vApp.$emit('analytics:cart', {
					action: 'remove',
					cartType: 'cart',
					items: {
						name: item.DisplayName,
						fgCode: item.Code,
						modelCode: item.ModelCode,
						price: item.LinkedPlacedPriceFormatted ?? item.PlacedPriceFormatted,
						priceDecimal: item.LinkedPlacedPrice ?? item.PlacedPrice,
						currencyCode: item.Currency,
						brand: item.Brand,
						quantityChange: item.Quantity
					}
				});
			},
			onClickBack: function ($event) {
				$event.preventDefault();
				window.location = '/';
			},
			onClickCheckout: function ($event) {
				vApp.$emit('analytics:checkout', this.$data.data.cart);
			}
		},
		mounted: function () {
			vApp.$on('cart:getCart:then', (res) => {
				console.log('cart:getCart:then', res);
				_.set(this.$data, 'data.cart', { ...res });
				_.set(this.$data, 'state.isEmpty', !res.TotalItems);
			});

			vApp.$on('cart:putCart:then', (res) => {
				console.log('cart:putCart:then', res);
				vApp.$emit('cart:getCart');
				vApp.$emit('cart:getCartItemCount');
			});

			vApp.$emit('cart:getCart');
		},
		beforeDestroyed: function () {
			vApp.$off('cart:getCart:then');
			vApp.$off('cart:putCart:then');
		}
	});
	global.vCart = vCart;
});
