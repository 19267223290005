export function initDeviceSize() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const aspectRatio = width / height;
    const deviceSize = {
        width,
        height,
        aspectRatio,
    };
    return { deviceSize };
}