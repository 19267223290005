$(function() {
	const $mapContainer = $('#storemapper');

	const loadCheck = setInterval(function() {
		const $box = $('#storemapper-left');
		if ($box.length) {
			clearInterval(loadCheck);
			const $title = $('#dealer-locator-title');
			setPadding($box, $title);
			$(window).resize(function() {
				setPadding($box, $title);
			});
		}
	}, 100);

	function setPadding($box, $title) {
		$box.css('padding-top', ($title.height() + 38) + 'px');
	}
});
