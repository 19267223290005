const Collapse = function($element) {
	this.namespace = 'Collapse';
	this.$element = $element;

	this.classParent = 'collapse';
	this.classParentMobileOnly = 'collapse--mobile';
	this.classParentOpen = 'collapse--open';
	this.classTrigger = 'collapse-trigger';

	this.$trigger = $element.find('.' + this.classTrigger);

	this.isMobileOnly = this.$element.hasClass(this.classParentMobileOnly);
	this.isMobile = false;

	this.init();
};

Collapse.prototype = {
	init: function() {
		const self = this;

		enquire.register('screen and (max-width:1024px)', {
			match: function() {
				self.isMobile = true;
			},
			unmatch: function() {
				self.isMobile = false;
				if (self.isMobileOnly) self.$element.removeClass(this.classParentOpen);
			}
		});

		if (self.$trigger.length) {
			self.$trigger.on('click.' + self.namespace, function(e) {
				e.preventDefault();

				if (self.isMobile || !self.isMobileOnly) {
					if (self.$element.hasClass(self.classParentOpen)) {
						self.$element.removeClass(self.classParentOpen);
					} else {
						self.$element.addClass(self.classParentOpen);
					}
				}
			});
		}
	},
};

$(function() {
	const $elements = $('.collapse');
	if ($elements.length != 0) {
		$elements.each(function() {
			new Collapse($(this));
		});
	}
});
