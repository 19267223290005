import * as _ from 'lodash';
import Vue from 'vue';

$(function () {
	const el = '.v-loading-bar';
	const $el = $(el);
	if (!$el.length) {
		return;
	}
	const vLoadingBar = new Vue({
		el: el,
		name: el,
		data: {
			data: {
			
			},
			state: {
				show: false
			}
		},
		methods: {

		},
		mounted: function () {
			vApp.$on('loadingBar:show', () => {
				console.log('loadingBar:show');
				this.$data.state.show = true;
				_.delay(() => this.$data.state.show = false, 10000);
			});
			vApp.$on('loadingBar:hide', () => {
				console.log('loadingBar:hide');
				this.$data.state.show = false;
			});
		},
		beforeDestroyed: function () {
			vApp.$off('loadingBar:show');
			vApp.$off('loadingBar:hide');
		}
	});

	global.vLoadingBar = vLoadingBar;
});