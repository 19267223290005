const ServiceListing = function($element, isSearchPage) {
	this.namespace = 'ServiceListing';
	this.$form = $element;
	this.$document = $(document);
	this.$body = $('body');
	this.$header = $('.header');
	this.$container = $('#service-listing-content');
	this.$mediaTable = $('#media-list');
	this.$productFilters = $('#media-filters');
	this.$breadcrumbs = $('#media-listing-breadcrumbs, #media-listing-filters-selected');
	this.$btnRefine = $('#btn-media-refine');
	this.$filterContainer = $('#media-filter-container');
	this.$clearAllBtn = $('#btn-media-clear-all');
	this.$filterCheckboxes = $element.find('.listing-filters input[type="checkbox"]');
	this.$filterULs = $element.find('.listing-filters-section ul');
	this.$sortBys = $element.find('select.sort-dropdown');
	this.$filterToggles = $element.find('a.listing-filters-toggle');
	this.$mobileCloseBtns = $element.find('#btn-media-filters-close, #btn-media-done-top, #btn-media-done-bottom');
	this.$pageCounter = $element.find('#service-current-page');
	this.$pageTotal = $element.find('#service-total-pages');
	this.$nextPageBtn = $element.find('#service-next-page');
	this.$prevPageBtn = $element.find('#service-prev-page');
	this.$pagingContainer = $element.find('#service-paging');
	this.$showRecentCheck = $element.find('#service-listing-recent-checkbox');

	this.page = 1;
	this.totalPages = parseInt(this.$pageTotal.html());
	this.showRecent = false;
	this.baseUrl = location.protocol + '//' + location.host + location.pathname;
	this.isSearchPage = isSearchPage;

	this.implicitFilters = this.$form.data('implicitfilters');
	this.rootFormAction = this.$form.attr('action');

	this.cssCheckboxDisabled = 'custom-checkbox--disabled';
	this.cssToggleOpen = 'listing-filters-section-content--open';
	this.cssMenuOpen = 'menu-open';
	this.cssFilterMenuOpen = 'listing-filters--open';
	this.cssAjaxLoading = 'listing--loading';
	this.cssPagingDisabled = 'paging-arrow--disabled';

	this.init();
};

ServiceListing.prototype = {
	init: function() {
		const self = this;

		self.$prevPageBtn.on('click.' + self.namespace, function(e) {
			e.preventDefault();

			if (self.page != 1) {
				self.loadPage(self.page - 1);
			}
		});

		self.$nextPageBtn.on('click.' + self.namespace, function(e) {
			e.preventDefault();

			if (self.page < self.totalPages) {
				self.loadPage(self.page + 1);
			}
		});

		self.$showRecentCheck.on('click.' + self.namespace, function(e) {
			self.showRecent = self.$showRecentCheck.is(':checked');
			self.updateFilters();
		});

		self.$filterCheckboxes.on('click.' + self.namespace, function(e) {
			self.updateFilters();
		});

		self.$filterULs.each(function() {
			const $this = $(this);

			if ($this.children().length <= 5) {
				$this.css('max-height', 'none');
			} else {
				let height = 0;
				for (let i = 0; i < 5; i++) {
					height = height + $this.children().eq(i).outerHeight(true);
				}
				$this.css('max-height', height + 'px');
			}
		});

		self.$filterToggles.on('click.' + self.namespace, function(e) {
			e.preventDefault();
			const $this = $(this);
			const $parent = $this.parent();

			if ($parent.hasClass(self.cssToggleOpen)) {
				$parent.removeClass(self.cssToggleOpen);
				$this.html($this.data('more'));
			} else {
				$parent.addClass(self.cssToggleOpen);
				$this.html($this.data('less'));
			}
		});

		self.$document.on('click.' + self.namespace, 'li.listing-breadcrumbs--tag a', function(e) {
			e.preventDefault();
			const $this = $(this);

			const $checkbox = $('#' + $this.data('filterid'));
			$checkbox.prop('checked', false);
			$this.parent().remove();

			self.updateFilters();
		});

		self.$btnRefine.on('click.' + self.namespace, function(e) {
			e.preventDefault();
			self.$filterContainer.addClass(self.cssFilterMenuOpen);
			self.$filterContainer.css('top', $(window).scrollTop() + 'px');
			self.$body.addClass(self.cssMenuOpen);
			self.$header.css('z-index', '1');
		});

		self.$mobileCloseBtns.on('click.' + self.namespace, function(e) {
			e.preventDefault();
			self.$filterContainer.removeClass(self.cssFilterMenuOpen);
			self.$body.removeClass(self.cssMenuOpen);
			self.$header.css('z-index', '10');
		});

		self.$clearAllBtn.on('click.' + self.namespace, function(e) {
			e.preventDefault();

			self.$filterCheckboxes.prop('checked', false);
			$('li.listing-breadcrumbs--tag').remove();

			self.updateFilters();
		});

		const existingFilters = self.getUrlParameter('filters');
		if (existingFilters.length) {
			// we've come back to the page with url params set
			var currPage = parseInt(self.getUrlParameter('page'), 10);
			self.page = (currPage && currPage >= 1) ? currPage : 1;
			self.showRecent = self.getUrlParameter('showRecent');

			const filterArray = existingFilters.split(',');

			self.setSelectedFilters(filterArray);

			const state = {
				filters: filterArray,
				showRecent: self.showRecent,
				page: self.page
			};
			self.loadMedia(state, false);
			window.history.replaceState(state, document.title, self.buildStateUrl(state));
		} else {
			// set an initial state
			const state = {
				filters: [],
				showRecent: self.showRecent,
				page: self.page
			};

			if (!self.isSearchPage) {
				window.history.replaceState(state, document.title, self.buildStateUrl(state));
			}
		}

		if (!self.isSearchPage) {
			$(window).on('popstate.' + self.namespace, function(e) {
				const requestData = e.originalEvent.state;
				console.log(requestData);
				self.page = requestData.page;
				self.showRecent = requestData.showRecent;

				self.setSelectedFilters(requestData.filters);

				// want to create state here to avoid pushing new state
				const state = {
					filters: self.getSelectedFilters(),
					showRecent: self.showRecent,
					page: self.page
				};

				self.loadMedia(state, false);
			});
		}
	},
	loadPage: function(newPage) {
		const self = this;

		self.page = newPage;
		const state = self.getNewState();
		self.loadMedia(state);
	},
	updateFilters: function() {
		const self = this;

		self.page = 1;
		const state = self.getNewState();
		self.loadMedia(state);
	},
	loadMedia: function(state) {
		const self = this;

		state.implicitFilters = self.implicitFilters;

		self.$container.addClass(self.cssAjaxLoading);

		$.ajax({
			contentType: 'application/json; charset=utf-8',
			url: self.rootFormAction + '/LoadMedia',
			method: 'GET',
			dataType: 'json',
			traditional: true,
			data: state,
			success: function(data) {
				self.updatePaging(data.TotalPages);
				self.updateMediaTable(data.Media);
				self.updateBreadcrumbs(data.SelectedFilterBreadcrumbHtml);
				self.$container.removeClass(self.cssAjaxLoading);

				self.$filterCheckboxes.not(':checked').each(function() {
					const $this = $(this);

					if (!self.arrayIncludes(data.AvailableFilters, $this.attr('id'))) {
						$this.attr('disabled', true);
						$this.parent().addClass(self.cssCheckboxDisabled);
					} else {
						$this.attr('disabled', false);
						$this.parent().removeClass(self.cssCheckboxDisabled);
					}
				});
			},
			error: function(xhr, ajaxOptions, thrownError) {
				console.log(xhr.status);
				console.log(thrownError);
				self.$container.removeClass(self.cssAjaxLoading);
			}
		});
	},
	updateMediaTable: function(mediaHtml) {
		const self = this;

		self.$mediaTable.html(mediaHtml);
	},
	updatePaging: function(total) {
		const self = this;

		self.totalPages = total;

		if (total == 1) {
			self.$pagingContainer.hide();
		} else {
			self.$pagingContainer.show();
			self.$pageCounter.html((self.page < 10) ? '0' + self.page : self.page);
			self.$pageTotal.html((total < 10) ? '0' + total : total);
		}

		if (self.page == 1) {
			self.$prevPageBtn.addClass(self.cssPagingDisabled);
		} else {
			self.$prevPageBtn.removeClass(self.cssPagingDisabled);
		}

		if (self.page == self.totalPages) {
			self.$nextPageBtn.addClass(self.cssPagingDisabled);
		} else {
			self.$nextPageBtn.removeClass(self.cssPagingDisabled);
		}
	},
	updateBreadcrumbs: function(html) {
		const self = this;

		self.$breadcrumbs.find('li.listing-breadcrumbs--tag').remove();
		self.$breadcrumbs.append(html);
	},
	getUrlParameter: function(name) {
		name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
		const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
		const results = regex.exec(location.search);
		return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
	},
	setSelectedFilters: function(filterArray) {
		const self = this;

		self.$filterCheckboxes.prop('checked', false);

		for (let i = 0; i < filterArray.length; i++) {
			const $checkBox = self.$filterCheckboxes.filter('[value="' + filterArray[i] + '"]');
			$checkBox.prop('checked', true);
		}
	},
	getSelectedFilters: function() {
		const self = this;

		const selectedFilters = [];
		self.$filterCheckboxes.filter(':checked').each(function() {
			selectedFilters.push($(this).attr('value'));
		});

		return selectedFilters;
	},
	getNewState: function() {
		const self = this;

		const state = {
			filters: self.getSelectedFilters(),
			showRecent: self.showRecent,
			page: self.page
		};

		if (!self.isSearchPage) {
			window.history.pushState(state, document.title, self.buildStateUrl(state));
		}

		return state;
	},
	buildStateUrl: function(state) {
		const self = this;

		let url = self.baseUrl + '?filters=' + state.filters.join(',') + '&showRecent=' + state.showRecent + '&page=' + state.page;

		return url;
	},
	arrayIncludes: function(arr, value) {
		for (let i = 0; i < arr.length; i++) {
			if (arr[i] === value) {
				return true;
			}
		}
		return false;
	}
};

$(function() {
	const $element = $('#service-listing-form');
	const isSearchPage = $('#search-results').length != 0;

	if ($element.length != 0) {
		new ServiceListing($element, isSearchPage);
	}
});
