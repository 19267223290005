$(function() {
	const $responsiveBgs = $('.responsive-background');
	if ($responsiveBgs.length) {
		enquire.register('screen and (max-width:768px)', {
			match: function() {
				$responsiveBgs.each(function() {
					const $banner = $(this);
					if (typeof ($banner.data('mobile-background')) !== 'undefined' && $banner.data('mobile-background').length) {
						$banner.attr('style', $banner.data('mobile-background'));
					} else if (typeof ($banner.data('background')) !== 'undefined' && $banner.data('background').length) {
						$banner.attr('style', $banner.data('background'));
					}
				});
			},
			unmatch: function() {
				$responsiveBgs.each(function() {
					const $banner = $(this);
					if (typeof ($banner.data('background')) !== 'undefined' && $banner.data('background').length) {
						$banner.attr('style', $banner.data('background'));
					} else if (typeof ($banner.data('mobile-background')) !== 'undefined' && $banner.data('mobile-background').length) {
						$banner.attr('style', $banner.data('mobile-background'));
					}
				});
			}
		});
	}
});
