const ParallaxPromo = function($element) {
	this.namespace = 'ParallaxPromo';
	this.$element = $element;
	this.$document = $(document);
	this.$window = $(window);
	this.$body = $('body');
	this.$pageNavigation = $('#page-navigation');
	this.$skipButton = $element.find('.parallax-product-promo-buttons-button-skip');
	this.$topButton = $element.find('.parallax-product-promo-buttons-button-top');

	this.directionUp = 'up';
	this.directionDown = 'down';
	this.videoStatusNotPlayed = 1;
	this.videoStatusPlayed = 2;
	this.classSection = 'parallax-product-promo-section';
	this.classShowSection = 'parallax-product-promo-section--show';
	this.classContainer = 'parallax-product-promo-section-container';
	this.classShowContainer = 'parallax-product-promo-section-container--show';

	this.$allSections = $element.find('.' + this.classSection);
	this.sectionCount = this.$allSections.length;
	this.$allContentContainers = $element.find('.' + this.classContainer);
	this.$allVideos = $element.find('video');

	this.videoAspectRatio = 1.777173913043478;

	this.initiated = false;
	this.complete = false;
	this.sectionPlaying = false;
	this.activeSection = -1;
	this.timer = null;
	this.isMobile = false;

	this.init();
};

ParallaxPromo.prototype = {
	init: function() {
		const self = this;

		self.binds();

		self.desktopInit();
		enquire.register('screen and (max-width:1024px)', {
			match: function() {
				self.isMobile = true;
				self.mobileInit();
			},
			unmatch: function() {
				self.isMobile = false;
				self.desktopInit();
			}
		});

		self.adjustVideoAspectRatio();
		self.$window.resize(function() {
			self.adjustVideoAspectRatio();
		});
	},
	desktopInit: function() {
		const self = this;

		self.$allSections.first().addClass(self.classShowSection);

		self.$document.unbind('scroll.' + self.namespace);
		self.$document.unbind('wheel.' + self.namespace);

		self.$document.on('scroll.' + self.namespace, function() {
			self.desktopScrollHandler(self);
		});

		self.$document.bind('wheel.' + self.namespace, function(event) {
			self.wheelHandler(event, self);
		});
	},
	mobileInit: function() {
		const self = this;

		self.$document.unbind('scroll.' + self.namespace);
		self.$document.unbind('wheel.' + self.namespace);

		self.$document.on('scroll.' + self.namespace, function() {
			self.mobileScrollHandler(self);
		});
	},
	binds: function() {
		const self = this;
		self.skipVideos = self.skipVideos.bind(self);
		self.scrollToTop = self.scrollToTop.bind(self);

		self.$skipButton.on('click', self.skipVideos);
		self.$topButton.on('click', self.scrollToTop);
	},
	adjustVideoAspectRatio: function() {
		const self = this;

		const windowWidth = self.$window.width();
		let windowHeight = self.$window.height();

		// adjustment for mobile where the video height is fixed at 350px;
		if (self.isMobile) {
			windowHeight = 350;
		}

		const windowAspectRatio = windowWidth / windowHeight;

		if (windowAspectRatio < self.videoAspectRatio) {
			const width = windowHeight * self.videoAspectRatio;
			self.$allVideos.css({
				'width': width + 'px',
				'height': '100%'
			});
		} else {
			self.$allVideos.css({
				'width': '100%',
				'height': 'auto'
			});
		}
	},
	elementBottomInView: function($elem) {
		const self = this;

		const elementBottom = $elem.offset().top + $elem.outerHeight();
		const currentViewportBottom = self.$window.scrollTop() + self.$window.height();

		return currentViewportBottom >= elementBottom;
	},
	elementTopInView: function() {
		const self = this;

		const elementTop = self.$element.offset().top;
		const currentViewportTop = self.$window.scrollTop();

		return elementTop >= currentViewportTop;
	},
	desktopScrollHandler: function(self) {
		if (self.complete) { // scrolling back up into element
			if (self.elementTopInView()) {
				self.complete = false;
				self.lockViewport();
				self.timer = setInterval(function() {
					self.checkScrollPosition();
				}, 100);
			}
		} else if (!self.initiated) { // scrolling down into element
			if (self.elementBottomInView(self.$element)) {
				self.initiated = true;
				self.activeSection = 0;
				self.lockViewport();
				self.initiateSection(self.activeSection);
				self.timer = setInterval(function() {
					self.checkScrollPosition();
				}, 100);
			}
		}
	},
	mobileScrollHandler: function(self) {
		for (let i = (self.$allSections.length - 1); i >= 0; i--) {
			const $section = $(self.$allSections[i]);
			if (self.elementBottomInView($section)) {
				self.initiated = true;
				self.activeSection = i;
				self.initiateSection(self.activeSection);
				break;
			}
		}
	},
	wheelHandler: function(event, self) {
		if (event.originalEvent.deltaY < 0) {
			self.processNavigationEvent(self.directionUp);
		} else {
			self.processNavigationEvent(self.directionDown);
		}
	},
	processNavigationEvent: function(direction) {
		const self = this;
		if (!self.initiated || self.sectionPlaying || self.complete) return;

		clearInterval(self.timer);

		let newActiveNdx;
		if (direction == self.directionUp) {
			newActiveNdx = self.activeSection - 1;
		} else {
			newActiveNdx = self.activeSection + 1;
		}

		self.navigateToSection(newActiveNdx);
	},
	initiateSection: function(sectionNdx) {
		const self = this;
		const $section = self.$element.children().eq(sectionNdx);
		const videoStatus = self.getVideoStatus($section);

		if (videoStatus == self.videoStatusNotPlayed) {
			const $video = $section.find('video');
			const textDisplayDuration = self.getDuration($section);

			self.sectionPlaying = true;

			// set event for video ended
			$video.on('ended', function() {
				self.setVideoStatus($section, self.videoStatusPlayed);
				self.sectionPlaying = false;
			});

			// plays video and toggles text animation
			$video.get(0).play();

			setTimeout(function() {
				$section.find('.' + self.classContainer).addClass(self.classShowContainer);
			}, textDisplayDuration);
		} else {
			self.sectionPlaying = true;
			setTimeout(function() {
				self.sectionPlaying = false;
			}, 750);
		}
	},
	navigateToSection: function(newSectionNdx) {
		const self = this;
		// if going up, reset the last section
		if (newSectionNdx < self.activeSection) {
			self.resetActiveSection();
		}

		if (newSectionNdx < 0) {
			// unlock viewport
			self.initiated = false;
			self.activeSection = -1;
			self.unlockViewport(self.directionUp);
		} else if (newSectionNdx === self.sectionCount) {
			// end of videos
			self.complete = true;
			self.unlockViewport(self.directionDown);
		} else {
			// internal naviation
			self.$allSections.eq(self.activeSection).removeClass(self.classShowSection);
			self.$allSections.eq(newSectionNdx).addClass(self.classShowSection);
			self.activeSection = newSectionNdx;
			self.initiateSection(newSectionNdx);
		}
	},
	lockViewport: function() {
		const self = this;
		self.$body.css('overflow', 'hidden');
		self.$pageNavigation.css('display', 'none');
		self.checkScrollPosition();
	},
	unlockViewport: function(direction) {
		const self = this;

		let delta = 0;
		if (direction === self.directionUp) {
			delta = -50;
		} else if (direction === self.directionDown) {
			delta = 50;
		}

		self.$document.scrollTop(self.$window.scrollTop() + delta);
		self.$body.css('overflow', 'visible');
		self.$pageNavigation.css('display', 'flex');
	},
	resetActiveSection: function() {
		const self = this;

		const $section = self.$allSections.eq(self.activeSection);

		// reset text
		self.$allContentContainers.eq(self.activeSection).removeClass(self.classShowContainer);

		// reset videos
		$section.attr('data-video-status', self.videoStatusNotPlayed);
		$section.find('video').each(function() {
			const video = $(this).get(0);
			video.currentTime = 0;
		});
	},
	// getVideoStatus: 1 - never played, 2 - ended
	getVideoStatus: function($section) {
		return $section.attr('data-video-status');
	},
	setVideoStatus: function($section, status) {
		$section.attr('data-video-status', status);
	},
	getDuration: function($section) {
		return $section.attr('data-duration') || 1000;
	},
	checkScrollPosition: function() {
		const self = this;
		if (self.$window.scrollTop() != self.$element.offset().top) {
			self.$document.scrollTop(self.$element.offset().top);
		}
	},
	skipVideos: function() {
		const self = this;
		clearInterval(self.timer);
		// scroll to next section
		$('html,body').animate({
			scrollTop: self.$element.next().offset().top
		}, 400, function() {
			// end each video
			self.$element.find('video').each(function(i, v) {
				const sectionParent = $(v).closest('.' + self.classSection);
				const $video = $(v)[0];
				$video.currentTime = $video.duration;
				self.setVideoStatus(sectionParent, self.videoStatusPlayed);
			});

			// show all video text
			self.$element.find('.' + self.classContainer).addClass(self.classShowContainer);
			// show last video in section
			$('.' + self.classSection).last().addClass(self.classShowSection).siblings().removeClass(self.classShowSection);

			self.initiated = true;
			self.complete = true;
			self.unlockViewport();
			self.activeSection = self.sectionCount;
		});
	},
	scrollToTop: function(direction) {
		const self = this;
		for (let i = self.activeSection; i >= 0; --i) {
			this.activeSection = i;
			self.resetActiveSection();
		}

		$('html,body').animate({
			scrollTop: self.$element.prev().offset().top
		}, 400, function() {
			clearInterval(self.timer);
			self.unlockViewport();
			self.initiated = false;
			self.complete = false;
			self.activeSection = -1;
			$('.' + self.classSection).first().addClass(self.classShowSection).siblings().removeClass(self.classShowSection);
		});
	}
};

$(function() {
	const $promos = $('.parallax-product-promo');
	if ($promos.length != 0) {
		$promos.each(function() {
			new ParallaxPromo($(this));
		});
	}
});
