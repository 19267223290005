$(function() {
	const $showMore = $('.product-detail-overview-readmore-more');
	const $showLess = $('.product-detail-overview-readmore-less');

	$('.product-detail-overview-readmore').on('click', function() {
		$(this).find($showMore).toggle();
		$(this).find($showLess).toggle();
		$(this).prev().toggleClass('show-all');
	});

	// set height of overview intro description text to always show 3 lines
	enquire.register('screen and (max-width:1024px)', {
		match: function() {
			// if description is less then 3 lines hide read/less copy
			const height = parseInt($('.product-detail-overview-description-text').css('line-height')) * 3;
			if (height >= $('.product-detail-overview-description-text').height()) {
				$showMore.first().hide();
				$showLess.first().hide();
			} else {
				$('.product-detail-overview-description-text').css('max-height', height);
			}

			// if list has 3 or less items hide more/less button
			const listLen = $('.product-detail-overview-list ul li').length;
			if (listLen <= 3) {
				$showMore.last().hide();
				$showLess.last().hide();
			}
		},
		unmatch: function() {
			$('.product-detail-overview-description-text').css('max-height', 'none');
		}
	});
});
