import Swiper, { Navigation, A11y } from 'swiper';


let videoSwiperArgs = {
    modules: [Navigation, A11y],
    slidesPerView: 'auto',
    spaceBetween: 26,
    a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
      },
    paginationElement: 'button',
    keyboard: {
        enabled: true
    },
    navigation: {
    },
    on: {
        reachEnd: function() {
            this.el.classList.remove('shadow-right')
        },
        reachBeginning: function() {
            this.el.classList.remove('shadow-left')
        },
        fromEdge: function() {
            this.el.classList.add('shadow-right')
            this.el.classList.add('shadow-left')
        }
    }
}


if (document.readyState !== 'loading') {
    console.log('document ready');
    initVideoSwiper();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        console.log('document was not ready');
        initVideoSwiper();
    });
}


function initVideoSwiper() {
    console.log('swiper init')
    const swipers = Array.from(document.querySelectorAll('.video-slider-container'))
    if(swipers){
        for(let i = 0; i < swipers.length; i++){
            swipers[i].setAttribute('id', `swiper-${i}`)
            swipers[i].querySelector('.swiper-button-prev').classList.add(`swiper-button-prev-${i}`)
            swipers[i].querySelector('.swiper-button-next').classList.add(`swiper-button-next-${i}`)
            videoSwiperArgs.navigation.nextEl = `.swiper-button-next-${i}`
            videoSwiperArgs.navigation.prevEl = `.swiper-button-prev-${i}`

            const swiper = new Swiper(`#swiper-${i} .swiper`, videoSwiperArgs)
           
        }
    }
}

// add video listeners
const videoCards = Array.from(document.querySelectorAll('.slider-item-video'))

for(let item of videoCards){
    item.addEventListener('click', (e) => emitCardClick(e, item))
    item.addEventListener('keydown', (e) => emitCardClick(e, item))
}

// define custom event
const event = new Event('card-click', { bubbles: true, detail: 'detail' })

// listen for custom event
document.addEventListener('card-click', e => showVideo(e))

// Emit custom event
function emitCardClick(e, item){
    console.log('caught', e.target, item)
    if(e.code === "Space" || e.code === "Enter"){
        item.dispatchEvent(event)
    }
    if(e.type === 'click'){
        item.dispatchEvent(event) 
    }
    else return
}

// create modal
const videoSwiperModal = document.createElement('div')
videoSwiperModal.setAttribute('style', 'display:none;')
videoSwiperModal.setAttribute('id', 'video-swiper-modal')
document.body.appendChild(videoSwiperModal)

let videoSliderplayer;

// card click to open modal and set video url
function showVideo(e){
    console.log('show-video')
    videoSwiperModal.setAttribute('aria-modal', 'true')
    videoSwiperModal.setAttribute('style', 'display:block;')

    const videoId = e.target.dataset

    const template = `<div class="video-slider-modal-content"><div id="video-slider-modal-player"></div></div>`
    videoSwiperModal.innerHTML = template

    // see youtube init file for youtube player api
    videoSliderplayer = window.videoSliderplayer = new YT.Player('video-slider-modal-player', {
        videoId: videoId.videoId,
        playerVars: {
            enablejsapi: 1,
            autoplay: 1,
            origin: 'https://sram.com',
        },
       
        
        events: {}
    });

    // close on escape
    document.addEventListener('keydown', handleKeyCloseModal)
    
    // close on click
    videoSwiperModal.addEventListener('click', handleCloseModal)
}

function handleCloseModal(e) {
    videoSliderplayer.stopVideo()
    closeModal(videoSwiperModal)
}
function handleKeyCloseModal(e) {
    if(e.code === 'Escape'){
        videoSliderplayer.stopVideo()
        closeModal(videoSwiperModal)
    }
}

function closeModal(modal){
    modal.removeAttribute('aria-modal')
    modal.setAttribute('style', 'display: none;')
    modal.removeEventListener('click',handleCloseModal)
    document.removeEventListener('keydown', handleKeyCloseModal)
}
  