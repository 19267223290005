const threeUpResponsive = [{
	breakpoint: 1024,
	settings: {
		slidesToShow: 3,
		slidesToScroll: 1
	}
},
{
	breakpoint: 768,
	settings: {
		slidesToShow: 2,
		slidesToScroll: 1,
	}
},
{
	breakpoint: 0,
	settings: {
		slidesToShow: 1,
		slidesToScroll: 1
	}
}];

const threeUpArgs = {
	infinite: true,
	slidesToShow: 3,
	slidesToScroll: 3,
	mobileFirst: true,
	arrows: true,
	responsive: threeUpResponsive
};

$(function() {
	$('.three-up .three-up-carousel-container').each(function() {
		const $this = $(this);
		const $component = $this.closest('.three-up');
		const totalChildren = $this.children().length;

		threeUpArgs.nextArrow = $component.find('.carousel-arrows-arrow--next');
		threeUpArgs.prevArrow = $component.find('.carousel-arrows-arrow--prev');

		// init slider
		$this.slick(threeUpArgs);

		// init paging
		if ($component.find('.carousel-arrows--alt').length != 0) {
			let totalString = '/ ';
			totalString += (totalChildren < 10) ? '0' : '';
			totalString += totalChildren;

			$component.find('.carousel-arrows--alt-pager-total').text(totalString);
			$this.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
				let nextString = (nextSlide < 11) ? '0' : '';
				nextString += (nextSlide + 1) + ' ';
				$component.find('.carousel-arrows--alt-pager-current').html(nextString);
			});
		}
	});
});
