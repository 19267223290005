const Newsletter = function($element) {
	this.namespace = 'Newsletter';
    this.$container = $element;
    this.$modal = $("#newsletter-modal");
	this.$closeBtn = $('#close');
	this.$submitBtn = $('#newsletter-submit');
	this.$emailBox = $('#newsletter-email');
	this.$sectionBox = $('#newsletter-section');
	this.$subsectionBox = $('#newsletter-subsection');
	this.$messaging = $('#newsletter-messaging');

	this.emailRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	this.cssClassError = 'footer-newsletter-message--error';

	this.init();
};

Newsletter.prototype = {
	init: function () {

        const self = this;

        $.getJSON("/api/newsletter/list", function (data) {

            data.sort(self.newsletterCompare);

            var tree = document.createDocumentFragment();
            
            $.each(data, function (a, b) {
                const name = b.Name;
                const id = b.Id;

                var lbl = document.createElement("label");
                lbl.setAttribute("for", id);
                lbl.appendChild(document.createTextNode(name));

                var chk = document.createElement("input");
                chk.setAttribute("type", "checkbox");
                chk.setAttribute("id", id);
                chk.setAttribute("class", "newsletterCheckBox");
                chk.setAttribute("value", name);

                var para = document.createElement("p");
                para.setAttribute("class", "mb-2 flex");
                para.appendChild(chk);
                para.appendChild(lbl)

                tree.appendChild(para);
            });

            $("#newsletter-items").append(tree);
            

        });

        self.$closeBtn.click(function () {
            event.preventDefault();
            $("#newsletter-signup").trigger("reset");
            $(".footer-newsletter-message").hide();
            $("#newsletter-modal").hide();
        });

        self.$submitBtn.on('click.' + self.namespace, function (e) {
            e.preventDefault();
            self.submit();
        });

        self.$emailBox.on('focus', function () {
            $(this).removeClass('error');
            self.hideMessaging();
        }).on('blur', function () {
            const $this = $(this);

            if ($this.val() != '' && !self.isEmail($this.val())) {
                $this.addClass('error');
                self.showMessaging('Please enter a valid email address.', true);
            }
        });

        $('#newsletter-items').on('change', '.newsletterCheckBox', function () {
            var checkBoxes = $('.newsletterCheckBox');
            $('#newsletter-submit').prop('disabled', checkBoxes.filter(':checked').length < 1);
        });
    },
    submit: function () {
        const self = this;
        const email = self.$emailBox.val();

        const idSelector = function () { return this.id; };
        const newsletters = Array.from(document.querySelectorAll('#newsletter-items .newsletterCheckBox:checked')).map((i) => i.id);
        
        self.hideMessaging();

        if (self.isEmail(email)) {

            $.ajax({
                contentType: 'application/json; charset=utf-8',
                url: '/api/newsletter/subscribe',
                method: 'POST',
                dataType: 'json',
                traditional: true,
                data: JSON.stringify({
                    email: email,
                    newsletterId: newsletters,
                }),
                success: function (data) {
                    if (data.Success) {
                        self.closeSignup();
                        vApp.$emit('snackbar:show', { msg: data.Message, err: false });
                    } else {
                        self.showMessaging(data.Message, true);
                        vApp.$emit('snackbar:show', { msg: data.Message, err: true });
                    }
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    console.log(xhr.status);
                    console.log(thrownError);
                    self.showMessaging('An error has occured with your submission. Please try again later.', true);
                }
            });
        } else {
            self.showMessaging('Please enter a valid email address.', true);
        }
    },
    showMessaging: function (msg, isError) {
        const self = this;

        self.$messaging.html(msg);
        if (isError) self.$messaging.addClass(self.cssClassError);
        else self.$messaging.removeClass(self.cssClassError);

        self.$messaging.show();
    },
    hideMessaging: function () {
        const self = this;

        self.$messaging.hide();
    },
    isEmail: function (email) {
        return this.emailRegex.test(email);
    },
    closeSignup: function () {
        const self = this;
        self.$modal.hide();
    },
    newsletterCompare: function (a, b) {
        if (a.Name < b.Name) {
            return -1;
        }
        if (a.Name > b.Name) {
            return 1;
        }
        return 0;
    },

};

$(function() {
	const $element = $('#newsletter-signup');

	if ($element.length != 0) {
		new Newsletter($element);
	}
});
